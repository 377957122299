import React, {useEffect, useMemo} from 'react';
import {useParams, Redirect} from 'react-router-dom';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import InviteCodeForm from 'components/InviteCodeForm';
import {createInviteCode, fetchInviteCodes} from 'state/actions/invite_codes';
import paths from 'pages/Router/paths';
import {useFormatMessage, useDynamicLinks} from 'hooks';

const schema = yup.object().shape({
	email: yup.string().email().required(),
	inviteCode: yup.string().required(),
	role: yup.string().required(),
});

const generateRandomDigits = () => {
	const min = 10000;
	const max = 99999;

	return Math.floor(Math.random() * (max - min) + min)
		.toString()
		.padStart(5, '0');
};

const User = () => {
	useDynamicLinks();
	const {id} = useParams();

	const isEditing = useMemo(() => !!id, [id]);

	const {success, inviteCodeData, error} = useSelector(
		state => ({
			success: state.inviteCodes.success,
			inviteCodeData: state.inviteCodes.data.find(user => user.id === id),
			error: state.inviteCodes.error,
		}),
		shallowEqual,
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isEditing) {
			if (!inviteCodeData) {
				dispatch(fetchInviteCodes(id));
			}
		}
	}, [isEditing, id, inviteCodeData, dispatch]);

	const redirect = ((isEditing && error) || success) && (
		<Redirect to={paths.INVITE_CODES} />
	);

	const editUserMessage = useFormatMessage('InviteCodes.edit');

	const newUserMessage = useFormatMessage('InviteCodes.new');

	const onSubmitHandler = value => {
		dispatch(createInviteCode(value));
	};

	return (
		<>
			{redirect}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<h1 className="title">
						{isEditing ? editUserMessage : newUserMessage}
					</h1>
				</div>
			</section>
			<section className="section is-main-section">
				{isEditing && !inviteCodeData ? (
					<ClipLoader />
				) : (
					<InviteCodeForm
						isEditing={isEditing}
						user={
							isEditing
								? inviteCodeData
								: {
										email: '',
										inviteCode: generateRandomDigits(),
										role: '',
								  }
						}
						onSubmitHandler={onSubmitHandler}
						schema={schema}
					/>
				)}
			</section>
		</>
	);
};

export default User;
