/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {Chip, Stack, Tooltip, IconButton} from '@mui/material';
import {useFormatMessage, useFormatDate, useDynamicLinks} from 'hooks';
import Table from 'components/Table';
import {
	fetchInviteCodes,
	deleteInivteCode,
	inviteCodeCleanUp,
} from 'state/actions/invite_codes';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import {capitalizeFirstLetter} from 'utils';
import {
	Visibility as VisibilityIcon,
	Block as BlockIcon,
	Restore as RestoreIcon,
} from '@mui/icons-material';
import classes from './Users.module.scss';

const Users = () => {
	useDynamicLinks();

	const {inviteCodeList, isAdmin, error, loading, deleted} = useSelector(
		state => ({
			inviteCodeList: state.inviteCodes.data,
			isAdmin: state.auth.userData.isAdmin || state.auth.userData.is_admin,
			error: state.inviteCodes.error,
			loading: state.inviteCodes.loading,
			deleted: state.inviteCodes.deleted,
		}),
		shallowEqual,
	);

	const [deleteModal, setDeleteModal] = useState({
		userId: null,
		isDeleted: false,
		isOpen: false,
	});

	const dispatch = useDispatch();

	const [search, setSearch] = useState('');

	useEffect(() => {
		if (isAdmin) {
			dispatch(fetchInviteCodes());
		}

		return () => dispatch(inviteCodeCleanUp());
	}, [dispatch, isAdmin]);

	useEffect(() => {
		if (deleted && !loading) {
			onCloseModalHandler();
		}
	}, [deleted, loading]);

	const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

	const onRemoveButtonClickHandler = (userId, isDeleted) => {
		setDeleteModal(prevState => ({
			userId,
			isDeleted,
			isOpen: !prevState.isOpen,
		}));
	};

	const onCloseModalHandler = () => {
		setDeleteModal({userId: null, isDeleted: false, isOpen: false});
	};

	const onDeleteUserHandler = () => {
		dispatch(deleteInivteCode(deleteModal.userId));
		onCloseModalHandler();
	};

	const columns = [
		{
			Header: useFormatMessage('InviteCodes.email'),
			accessor: 'email',
		},
		{
			Header: useFormatMessage('InviteCodes.inivteCode'),
			accessor: 'invite_code',
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Role</div>,
			accessor: 'role',
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<Chip label={capitalizeFirstLetter(row.original.role)} />
				</Stack>
			),
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Status</div>,
			accessor: 'deleted',
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<Chip
						label={row.original.deleted ? 'Inactive' : 'Active'}
						color={row.original.deleted ? 'warning' : 'success'}
					/>
				</Stack>
			),
		},
		{
			Header: useFormatMessage('InviteCodes.createdBy'),
			accessor: 'created_by',
			Cell: ({row}) => {
				return (
					<small className="has-text-grey is-abbr-like">
						{row.original.created_by}
					</small>
				);
			},
		},
		{
			Header: useFormatMessage('InviteCodes.created'),
			accessor: 'created_at',
			Cell: ({row}) => {
				return (
					<small className="has-text-grey is-abbr-like">
						{useFormatDate(row.original.created_at?.seconds * 1000, {
							weekday: 'short',
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						})}
					</small>
				);
			},
		},
		{
			Header: '',
			id: 'actions',
			accessor: 'actions',
			Cell: ({row}) => (
				<>
					{!row.original.isAdmin && (
						<div className="buttons is-right">
							{/* <Tooltip title={row.original.deleted ? "Active" : "Deactive"}>
								<IconButton
									color={row.original.deleted ? "success" : "warning"}
									component="span"
									onClick={() =>
										onRemoveButtonClickHandler(
											row.original.id,
											row.original.deleted,
										)
									}>
									{row.original.deleted ? <RestoreIcon /> : <BlockIcon />}
								</IconButton>
							</Tooltip> */}

							<Link to={`/invite-code-details/${row.original.id}`}>
								<IconButton color="primary" component="span">
									<VisibilityIcon />
								</IconButton>
							</Link>
						</div>
					)}
				</>
			),
			disableSortBy: true,
		},
	];

	const data = search
		? inviteCodeList.filter(el => {
				const clonedElem = {...el};
				delete clonedElem.id;
				delete clonedElem.deleted;
				return Object.values(clonedElem).some(field =>
					field.toString().includes(search.toLowerCase()),
				);
		  })
		: inviteCodeList;

	const deleteMessage = useFormatMessage('InviteCodes.disableButtonText');

	const confirmMessage = useFormatMessage('Users.confirm');

	const disableMessage = useFormatMessage('InviteCodes.disable');
	const enableMessage = useFormatMessage('InviteCodes.enable');

	const cancelMessage = useFormatMessage('Users.cancel');

	return (
		<>
			{redirect}
			{deleteModal.isOpen && (
				<ConfirmationModal
					isActive={deleteModal.isOpen}
					isLoading={loading}
					confirmButtonMessage={deleteMessage}
					title={confirmMessage}
					body={
						deleteModal.isDeleted
							? enableMessage
							: 'This will deactivate this invitation code to be used again.'
					}
					cancelButtonMessage={cancelMessage}
					onConfirmation={onDeleteUserHandler}
					onCancel={onCloseModalHandler}
				/>
			)}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<h1 className="title">
									{useFormatMessage('InviteCodes.InviteCodes')}
								</h1>
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">
								<Link to={paths.ADD_INVITE_CODE} className="button">
									{useFormatMessage('InviteCodes.new')}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section is-main-section">
				<div className="card has-table has-mobile-sort-spaced">
					<header className="card-header">
						<p className={classNames('card-header-title', classes.tableHeader)}>
							<span>{useFormatMessage('Users.search')}</span>
							<input
								type="text"
								className="input"
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</p>
					</header>
					<div className="b-table">
						{loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
						{error && 'Show error'}
					</div>
				</div>
			</section>
		</>
	);
};

export default Users;
