import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import InviteCodes from 'pages/InviteCodes';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import InviteCode from 'pages/InviteCode';

import UserDetails from 'pages/UserDetails';
import UserDetailsMessages from 'pages/UserDetails/Messages';

import InviteCodeDetails from 'pages/InviteCodeDetails';

import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={paths.LOGIN} component={Login} />
				<Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />

				<PrivateRoute path={paths.ADD_USER} component={User} />
				<PrivateRoute path={paths.MODIFY_USER} component={User} />
				<PrivateRoute path={paths.USERS} component={Users} />

				<PrivateRoute path={paths.ADD_INVITE_CODE} component={InviteCode} />
				<PrivateRoute path={paths.MODIFY_INVITE_CODE} component={InviteCode} />
				<PrivateRoute path={paths.INVITE_CODES} component={InviteCodes} />

				<PrivateRoute path={paths.PROFILE} component={Profile} />

				<PrivateRoute path={paths.USER_DETAILS} component={UserDetails} />
				<PrivateRoute
					path={paths.INVITE_CODE_DETAILS}
					component={InviteCodeDetails}
				/>
				<PrivateRoute
					path={paths.USER_DETAILS_MESSAGES}
					component={UserDetailsMessages}
				/>

				<PrivateRoute path={paths.ROOT} component={Home} />
				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default RouterComponent;
