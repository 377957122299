/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import {
	fetchUsers,
	deleteUser,
	enableUserAccount,
	updateUser,
	updateUserProfile,
} from 'state/actions/users';
import {updateDocument} from 'state/api/firestore';
import {useDynamicLinks} from 'hooks';
import ClipLoader from 'react-spinners/ClipLoader';
import {
	Box,
	TextField,
	Typography,
	Switch,
	Grid,
	IconButton,
	Tooltip,
	Stack,
	Button,
	FormControl,
	Chip,
	MenuItem,
	InputLabel,
} from '@mui/material';
import {Visibility as VisibilityIcon} from '@mui/icons-material';
import ConfirmationModal from 'components/ConfirmationModal';
import {capitalizeFirstLetter} from 'utils';
import _ from 'lodash';
import Select from '@mui/material/Select';
import {toastr} from 'react-redux-toastr';
import firebase, {getFunctions} from 'firebase.js';
import colors from 'styles/colors';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import moment from 'moment';
import Connection from './Connections';
import BlockedUsers from './BlockedUsers';
import {fetchDocument, fetchCollection} from '../../state/api';

export const OTHERS_OPTIONS = ['Adopted', 'NICU', 'Multiples'];

const mailService = getFunctions().httpsCallable('app/api/mail/send-mail');

const UserDetails = () => {
	useDynamicLinks();
	const {id} = useParams();
	const dispatch = useDispatch();

	const [bloquedModal, setBloquedModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [enable, setEnable] = useState(true);
	const [inviteCode, setInviteCode] = useState(null);
	const [DIETARY_OPTIONS] = useState([
		'Nuts-Free',
		'Dairy-Free',
		'Vegetarian',
		'Gluten-Free',
		'No Vitamins or Supplements',
		'No Prescription Medications',
		'Alcohol-Free',
		'Caffeine-Free',
	]);
	const [bannedModal, setBannedModal] = useState({
		userId: null,
		isOpen: false,
	});

	const [activeModal, setActiveModal] = useState({
		userId: null,
		isOpen: false,
	});

	const [adminModal, setAdminModal] = useState({
		userId: null,
		isOpen: false,
	});

	const [email, setEmail] = useState({
		subject: '',
		message: '',
	});

	const {userData, loading, deleted} = useSelector(
		state => ({
			success: state.users.success,
			userData: state.users.data.find(user => user.id === id),
			error: state.users.error,
			loading: state.users.loading,
			deleted: state.users.deleted,
		}),
		shallowEqual,
	);

	const [valueDate, setValueDate] = useState(new Date());

	const [user, setUser] = useState(null);
	const [dietaryOptions, setDietaryOptions] = useState([]);
	const [aditionalOptions, setAditionalOptions] = useState([]);

	useEffect(() => {
		if (id) {
			dispatch(fetchUsers(id));
		}
	}, [id, dispatch]);

	useEffect(() => {
		if (userData) {
			handleFetchInviteCodeInfo();
			setUser(userData);
			setDietaryOptions([
				...(userData?.dietaryIntake ? userData?.dietaryIntake : []),
			]);
			setAditionalOptions([...(userData?.others ? userData?.others : [])]);
			const date = new Date(
				moment(userData?.babyAge, 'DD-MM-YYYY').format('YYYY-MM-DD'),
			);
			setValueDate(date);
		}
	}, [userData]);

	useEffect(() => {
		if (deleted && !loading) {
			onHandleBannedCloseModal();
			onHandleActiveCloseModal();
			onHandlePermissionsModal(null, false);
			window.location.reload();
		}
	}, [deleted, loading]);

	const onHandleBannedModal = userId => {
		setBannedModal(prevState => ({userId, isOpen: true}));
	};

	const onHandleBannedCloseModal = userId => {
		setBannedModal(prevState => ({userId: null, isOpen: false}));
	};

	const onHandleActiveModal = userId => {
		setActiveModal(prevState => ({userId, isOpen: true}));
	};

	const onHandleActiveCloseModal = userId => {
		setActiveModal(prevState => ({userId: null, isOpen: false}));
	};

	const onDeleteUserHandler = async () => {
		setIsLoading(true);

		if (email.subject.length && email.message.length) {
			try {
				await mailService({
					mail_type: 'simple',
					to: userData?.email,
					...email,
				});
				setEmail({
					subject: '',
					message: '',
				});
			} catch (error) {
				setIsLoading(false);
			}
		}

		dispatch(deleteUser(bannedModal.userId));
	};

	const onHandlePermissionsModal = (id = null, close = null) => {
		setAdminModal(prevState => ({
			userId: id,
			isOpen: close != null ? false : !prevState.isOpen,
		}));
	};

	const onActiveUserHandler = () => {
		dispatch(enableUserAccount(activeModal.userId));
	};

	const handleFetchInviteCodeInfo = async () => {
		setIsLoading(true);
		let reponse = null;
		try {
			reponse = await fetchDocument('invite_codes', userData.invite_code_id);
		} catch (e) {
			setIsLoading(false);
			return;
		}
		setInviteCode(reponse);
		setIsLoading(false);
	};

	const onHandleBlockUser = async () => {
		setIsLoading(true);
		await updateDocument('users', userData.id, {
			bannedCommunications: true,
			bannedCommunicationsReason: '',
		});

		if (email.subject.length && email.message.length) {
			try {
				await mailService({
					mail_type: 'simple',
					to: userData?.email,
					...email,
				});
				setEmail({
					subject: '',
					message: '',
				});
			} catch (error) {
				setIsLoading(false);
			}
		}

		onHandleModal();
		setIsLoading(false);
		window.location.reload();
	};

	const onHandleUnBlockUser = async () => {
		setIsLoading(true);
		await updateDocument('users', userData.id, {
			bannedCommunications: false,
			bannedCommunicationsReason: '',
		});
		onHandleModal();
		setIsLoading(false);
		window.location.reload();
	};

	const onHandleModal = () => {
		setBloquedModal(state => !state);
	};

	const cancelMessage = 'Cancel';

	const onHandleSetAdminPermissions = async () => {
		dispatch(
			updateUser({
				userId: adminModal.userId,
				isAdmin: !userData?.isAdmin,
			}),
		);
	};

	const handleSaveUser = async () => {
		setIsLoading(true);
		const isChangeEmail = user?.email !== userData?.email;
		let updateJSON = {
			id: user?.id,
			name: user?.name || '',
			role: user?.role || '',
			phone: user?.phone || '',
			shortBio: user?.shortBio || '',
			ounces: user?.ounces || '',
			location: user?.location ? user?.location : null,
			dietaryIntake: user?.dietaryIntake ? user?.dietaryIntake : null,
			others: user?.others ? user?.others : null,
			email: user?.email,
			changeEmail: isChangeEmail,
			babyAge: user?.babyAge ? user?.babyAge : null,
		};

		if (isChangeEmail) {
			const existEmail = await fetchCollection('users', {
				queries: [
					{
						attribute: 'email',
						operator: '==',
						value: user?.email,
					},
				],
			});

			if (existEmail.length) {
				toastr.error('', 'The email is already in use');
				setIsLoading(false);
				return;
			}
		}

		if (!_.isEqual(user?.location, userData?.location)) {
			const reg = /^\d{5}(?:[-\s]\d{4})?$/;

			if (!reg.test(user?.location.zipCode || user?.location)) {
				toastr.error('', 'Invalid Zip Code');
				return false;
			}

			const locationAPI = getFunctions().httpsCallable(
				'app/api/location/find-by',
			);
			try {
				const resp = await locationAPI({
					type: 'address',
					query: user?.location.zipCode || user?.location,
				});

				updateJSON = {
					...updateJSON,
					location: resp.data,
				};
			} catch (error) {
				setIsLoading(false);
			}
		}

		dispatch(updateUserProfile(updateJSON));
		// setIsLoading(false);
	};

	const renderDietaryOption = (item, index) => {
		const selected = dietaryOptions.find(el => el === item) || false;
		return (
			<Chip
				key={`dietary-option-${item}-${index}`}
				label={item}
				color={!selected ? 'default' : 'secondary'}
				sx={{
					'& .MuiChip-label': {
						color: !selected ? colors.primary.blue500 : colors.white,
						fontWeight: !selected ? 'normal' : 'bold',
					},
				}}
				clickable
				onClick={() => handleSelectOption('dietaryIntake', item, selected)}
			/>
		);
	};

	const renderAditionalOption = (item, index) => {
		const selected = aditionalOptions.find(el => el === item) || false;

		return (
			<Chip
				key={`dietary-option-${item}-${index}`}
				label={item}
				color={!selected ? 'default' : 'secondary'}
				sx={{
					'& .MuiChip-label': {
						color: !selected ? colors.primary.blue500 : colors.white,
						fontWeight: !selected ? 'normal' : 'bold',
					},
				}}
				clickable
				onClick={() => handleSelectOption('others', item, selected)}
			/>
		);
	};

	const handleSelectOption = (type, option, isSelected) => {
		const arrayItems =
			type === 'dietaryIntake' ? [...dietaryOptions] : [...aditionalOptions];
		const indexOf = _.indexOf(arrayItems, option);

		if (indexOf !== -1 && isSelected) {
			arrayItems.splice(indexOf, 1);
		} else {
			arrayItems.push(option);
		}

		if (type === 'dietaryIntake') {
			setDietaryOptions([...arrayItems]);
		} else {
			setAditionalOptions([...arrayItems]);
		}

		setUser({
			...user,
			[type]: arrayItems,
		});
	};

	return (
		<React.StrictMode>
			{bloquedModal && (
				<ConfirmationModal
					isActive={bloquedModal}
					isLoading={isLoading || loading}
					confirmButtonMessage={
						userData?.bannedCommunications ? 'yes, unblock' : 'Yes, block'
					}
					title={
						userData?.bannedCommunications
							? 'Unblock the user'
							: 'Block the user'
					}
					body={
						userData?.bannedCommunications
							? 'Are sure you want to unblock the user from communicating with anyone, you can block him/her at any time.'
							: 'Are sure you want to block the user from communicating with anyone, you can unblock him/her at any time.'
					}
					cancelButtonMessage="Cancel"
					onConfirmation={() =>
						userData?.bannedCommunications
							? onHandleUnBlockUser()
							: onHandleBlockUser()
					}
					onCancel={onHandleModal}>
					{!userData?.bannedCommunications ? (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="caption">
									If you need to, you can write an email and send it to the
									user, indicating the reasons...
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Subject"
									placeholder="Ex: Banned Reason - The Drop Community"
									onChange={val =>
										setEmail({...email, subject: val.currentTarget.value})
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Message"
									multiline
									rows={4}
									placeholder="Ex: Laborum cupidatat commodo dolore duis. Ex amet laboris enim reprehenderit in duis est aute ut duis commodo non et. Incididunt culpa in laboris aliquip. Incididunt in est in ut nostrud incididunt voluptate aliqua. Esse elit laborum ad adipisicing deserunt qui irure sit id qui cupidatat. Irure proident quis culpa minim anim reprehenderit aute nostrud incididunt exercitation. Laborum ipsum officia minim et aliquip."
									onChange={val =>
										setEmail({...email, message: val.currentTarget.value})
									}
								/>
							</Grid>
						</Grid>
					) : undefined}
				</ConfirmationModal>
			)}
			{adminModal.isOpen && (
				<ConfirmationModal
					isActive={adminModal.isOpen}
					isLoading={isLoading || loading}
					confirmButtonMessage="Confirm"
					title="Admin permissions"
					body={
						userData?.isAdmin
							? 'Are sure you want to remove admin permission, you can set at any time.'
							: 'Are sure you want to add admin permissions, you can remove at any time.'
					}
					cancelButtonMessage="Cancel"
					onConfirmation={onHandleSetAdminPermissions}
					onCancel={() => onHandlePermissionsModal(null, false)}
				/>
			)}
			{activeModal.isOpen && (
				<ConfirmationModal
					isActive={activeModal.isOpen}
					isLoading={isLoading || loading}
					confirmButtonMessage="Yes, Active"
					title="Activate user account"
					body="This will unblock the user account, and the user will be able to log in to the platform again"
					cancelButtonMessage={cancelMessage}
					onConfirmation={onActiveUserHandler}
					onCancel={onHandleActiveCloseModal}
				/>
			)}
			{bannedModal.isOpen && (
				<ConfirmationModal
					isActive={bannedModal.isOpen}
					isLoading={isLoading || loading}
					confirmButtonMessage="Yes, Deactive"
					title="Deactivate user account"
					body="This will block the user account, and the user will not be able to log in to the platform again."
					cancelButtonMessage={cancelMessage}
					onConfirmation={onDeleteUserHandler}
					onCancel={onHandleBannedCloseModal}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="caption">
								If you need to, you can write an email and send it to the user,
								indicating the reasons...
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Subject"
								placeholder="Ex: Banned Reason - The Drop Community"
								onChange={val =>
									setEmail({...email, subject: val.currentTarget.value})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Message"
								multiline
								rows={4}
								placeholder="Ex: Laborum cupidatat commodo dolore duis. Ex amet laboris enim reprehenderit in duis est aute ut duis commodo non et. Incididunt culpa in laboris aliquip. Incididunt in est in ut nostrud incididunt voluptate aliqua. Esse elit laborum ad adipisicing deserunt qui irure sit id qui cupidatat. Irure proident quis culpa minim anim reprehenderit aute nostrud incididunt exercitation. Laborum ipsum officia minim et aliquip."
								onChange={val =>
									setEmail({...email, message: val.currentTarget.value})
								}
							/>
						</Grid>
					</Grid>
				</ConfirmationModal>
			)}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<h1 className="title">{userData?.name}</h1>
				</div>
			</section>
			<section className="section is-main-section">
				{!userData ? (
					<ClipLoader />
				) : (
					<>
						<div className="tile is-ancestor">
							<div className="tile is-parent">
								<div className="card tile is-child">
									<header className="card-header">
										<p className="card-header-title">
											<span className="icon">
												<i className="mdi mdi-account-settings default" />
											</span>
											Actions
										</p>
									</header>
									<div className="card-content">
										<Grid container>
											<Grid
												item
												xs={12}
												style={{display: 'flex', alignItems: 'center'}}>
												{isLoading || loading ? (
													<ClipLoader />
												) : (
													<Switch
														disabled={isLoading}
														checked={userData?.isAdmin}
														onChange={() =>
															onHandlePermissionsModal(userData.id)
														}
													/>
												)}
												<Typography>Admin Permissions</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												style={{display: 'flex', alignItems: 'center'}}>
												{isLoading || loading ? (
													<ClipLoader />
												) : (
													<Switch
														disabled={isLoading}
														checked={userData?.bannedSystem}
														onChange={() =>
															userData?.bannedSystem
																? onHandleActiveModal(userData.id)
																: onHandleBannedModal(userData.id)
														}
													/>
												)}
												<Typography>User account deactivated</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												style={{display: 'flex', alignItems: 'center'}}>
												{isLoading || loading ? (
													<ClipLoader />
												) : (
													<Switch
														disabled={isLoading}
														checked={userData?.bannedCommunications}
														onChange={onHandleModal}
													/>
												)}
												<Typography>
													Block the user to be able to communicate with anyone
												</Typography>
											</Grid>
										</Grid>
									</div>
								</div>
							</div>
						</div>
						<div className="tile is-ancestor">
							<div className="tile is-parent">
								<div className="card tile is-child">
									<header className="card-header">
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											style={{width: '100%', marginRight: '10px'}}>
											<p className="card-header-title">
												<span className="icon">
													<i className="mdi mdi-account-edit default" />
												</span>
												User Information
											</p>

											<Button
												disabled={_.isEqual(userData, user) || isLoading}
												onClick={handleSaveUser}>
												{isLoading ? (
													<ClipLoader />
												) : (
													<Typography style={{textTransform: 'none'}}>
														Save
													</Typography>
												)}
											</Button>
										</Stack>
									</header>
									<div className="card-content">
										<Grid container spacing={2}>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													variant="outlined"
													id="outlined-required"
													label="Email"
													value={user?.email || userData?.email}
													defaultValue={userData?.email}
													onChange={val =>
														setUser({...user, email: val.target.value})
													}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													variant="outlined"
													id="outlined-required"
													label="Name"
													value={user?.name || userData?.name}
													defaultValue={userData?.name}
													onChange={val =>
														setUser({...user, name: val.target.value})
													}
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													variant="outlined"
													label="Phone"
													value={user?.phone || userData?.phone}
													defaultValue={userData?.phone}
													onChange={val =>
														setUser({...user, phone: val.target.value})
													}
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DesktopDatePicker
														label="Baby's Age"
														inputFormat="dd/MM/yyyy"
														value={valueDate}
														onChange={val => {
															setValueDate(val);
															const formatted =
																moment(val).format('DD-MM-YYYY');
															setUser({...user, babyAge: formatted});
														}}
														minDate={new Date().setFullYear(
															new Date().getFullYear() - 2,
														)}
														maxDate={new Date()}
														renderInput={params => (
															<TextField fullWidth {...params} />
														)}
													/>
												</LocalizationProvider>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													fullWidth
													variant="outlined"
													id="outlined-required"
													label="Location"
													value={
														user?.location?.zipCode ||
														userData?.location?.zipCode
													}
													defaultValue={userData?.location?.zipCode}
													onChange={val =>
														setUser({
															...user,
															location: {
																...user?.location,
																zipCode: val.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-label">
														Role
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														value={capitalizeFirstLetter(
															user?.role || userData?.role,
														)}
														label="Role"
														onChange={e =>
															setUser({
																...user,
																role: e.target.value.toLowerCase(),
															})
														}>
														<MenuItem value="Donor">Donor</MenuItem>
														<MenuItem value="Recipient">Recipient</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={6}>
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-label">
														{userData?.role === 'donor'
															? 'Ounces Needed'
															: 'Ounces Available'}
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														label="Ounces Needed"
														value={user?.ounces || userData?.ounces}
														onChange={e =>
															setUser({...user, ounces: e.target.value})
														}>
														<MenuItem value="40-60">40-60 oz</MenuItem>
														<MenuItem value="60-80">60-80 oz</MenuItem>
														<MenuItem value="80-100">80-100 oz</MenuItem>
														<MenuItem value="-1--1">Over 100 oz</MenuItem>
													</Select>
												</FormControl>
											</Grid>

											<Grid item xs={12}>
												<TextField
													fullWidth
													variant="outlined"
													label="Short Bio"
													multiline
													rows={2}
													value={user?.shortBio || userData?.shortBio}
													defaultValue={userData?.shortBio}
													onChange={val =>
														setUser({...user, shortBio: val.target.value})
													}
												/>
											</Grid>

											<Grid item xs={12}>
												<Typography>Dietary Intake</Typography>
												<Stack direction="row" spacing={1} sx={{mt: 1}}>
													{DIETARY_OPTIONS.map(renderDietaryOption)}
												</Stack>
											</Grid>

											<Grid item xs={12}>
												<Typography>Aditional Info(Others)</Typography>
												<Stack direction="row" spacing={1} sx={{mt: 1}}>
													{OTHERS_OPTIONS.map(renderAditionalOption)}
												</Stack>
											</Grid>
										</Grid>
									</div>
								</div>
							</div>
						</div>

						{inviteCode ? (
							<div className="tile is-ancestor">
								<div className="tile is-parent">
									<div className="card tile is-child">
										<header className="card-header">
											<p className="card-header-title">
												<span className="icon">
													<i className="mdi mdi-account-edit default" />
												</span>
												Invite Code Information
												<Tooltip title="Show Invite Code Details">
													<Link to={`/invite-code-details/${inviteCode?.id}`}>
														<IconButton color="primary" component="span">
															<VisibilityIcon />
														</IconButton>
													</Link>
												</Tooltip>
											</p>
										</header>
										<div className="card-content">
											<Grid container spacing={2}>
												<Grid item xs={12}>
													{(inviteCode?.email).toLowerCase() !==
													(userData?.email).toLowerCase() ? (
														<TextField
															fullWidth
															variant="outlined"
															disabled
															id="outlined-required"
															label="Invite By"
															defaultValue={inviteCode?.email}
														/>
													) : undefined}
												</Grid>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth
														variant="outlined"
														disabled
														id="outlined-required"
														label="Invite Code"
														defaultValue={inviteCode?.invite_code}
													/>
												</Grid>
												<Grid item xs={12} md={6}>
													<TextField
														fullWidth
														variant="outlined"
														disabled
														id="outlined-required"
														label="Role Selected"
														defaultValue={capitalizeFirstLetter(
															inviteCode?.role,
														)}
													/>
												</Grid>
											</Grid>
										</div>
									</div>
								</div>
							</div>
						) : undefined}

						<Connection />
						<BlockedUsers />
					</>
				)}
			</section>
		</React.StrictMode>
	);
};

export default UserDetails;
