/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import classNames from 'classnames';

import classes from './Footer.module.scss';

const Footer = () => {
	return (
		<footer
			className="footer"
			style={{
				alignSelf: 'end',
				width: '100%',
			}}>
			<div className="container-fluid">
				<div className={classNames('level', classes.level)}>
					<div className="level-left">
						<div className="level-item">
							© {new Date().getFullYear()}{' '}
							<span>&nbsp; Shockoe.com LLC, All Rights Reserved</span>
						</div>
					</div>
					<div className={classNames('level-right', classes.levelRight)}>
						<div className="level-item">
							<div
								className="logo"
								style={{backgroundColor: '#F26C20', padding: '15px 20px'}}>
								<a href="https://shockoe.com" target="_blank">
									<img
										src="https://shockoe.com/wp-content/uploads/2020/05/logo-Shockoe.png"
										alt="Shockoe.com"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
