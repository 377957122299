/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {env} from './env';

const configurationSets = {
	local: {
		REACT_APP_LOGIN_PAGE_URL: 'http://localhost:3000/login',
		REACT_APP_FIRE_BASE_FUNCTIONS_PORT: 5001,
		firebase: {
			apiKey: 'AIzaSyC7UEIG9u2CCcn8QHNO1qzBexpOdJtY-fs',
			authDomain: 'http://localhost:4000/auth',
			databaseURL: 'http://localhost:4000/firestore',
			projectId: 'thedrop-80685',
			storageBucket: 'thedrop-80685.appspot.com',
		},
	},
	development: {
		REACT_APP_LOGIN_PAGE_URL: 'https://thedrop.shockoe.dev/login',
		REACT_APP_FIRE_BASE_FUNCTIONS_PORT: 5001,
		firebase: {
			apiKey: 'AIzaSyC7UEIG9u2CCcn8QHNO1qzBexpOdJtY-fs',
			authDomain: 'thedrop-80685.firebaseapp.com',
			databaseURL: 'https://thedrop-80685.firebaseio.com',
			projectId: 'thedrop-80685',
			storageBucket: 'thedrop-80685.appspot.com',
		},
	},
	qa: {
		REACT_APP_LOGIN_PAGE_URL: 'https://qa-and-staging.firebaseapp.com/login',
		REACT_APP_FIRE_BASE_FUNCTIONS_PORT: 5001,
		firebase: {
			apiKey: 'AIzaSyCieWpJW85OmzeWaGxPengwI0xjtyhHdOg',
			authDomain: 'the-drop-qa.firebaseapp.com',
			databaseURL: 'https://the-drop-qa.firebaseio.com',
			projectId: 'the-drop-qa',
			storageBucket: 'the-drop-qa.appspot.com',
		},
	},
	production: {
		REACT_APP_LOGIN_PAGE_URL: 'https://admin.sharethedrop.com/login',
		REACT_APP_FIRE_BASE_FUNCTIONS_PORT: 5001,
		firebase: {
			apiKey: 'AIzaSyCyruQbsc_LrcuaSouQv7E_yL8oWhnNsGs',
			authDomain: 'the-drop-production.firebaseapp.com',
			databaseURL: 'https://the-drop-production.firebaseio.com',
			projectId: 'the-drop-production',
			storageBucket: 'the-drop-production.appspot.com',
		},
	},
};

export const permissions =
	configurationSets[env] || configurationSets.development;
