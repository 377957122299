/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, {useEffect, useState, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {useSelector, shallowEqual} from 'react-redux';
import {
	useDynamicLinks,
	useAgroupMessages,
	useFormatDateTime,
	useFormatDate,
} from 'hooks';
import {
	Box,
	TextField,
	Grid,
	Typography,
	Tooltip,
	Chip,
	Stack,
	Avatar,
	IconButton,
	InputAdornment,
	Button,
} from '@mui/material';
import {
	Restore as RestoreIcon,
	Block as BlockIcon,
	Search as SearchIcon,
	Visibility as VisibilityIcon,
	Clear as ClearIcon,
} from '@mui/icons-material';
import colors from 'styles/colors';
import {getFirestoreRef, createDocument} from 'state/api/firestore';
import ConfirmationModal from 'components/ConfirmationModal';
import {toastr} from 'react-redux-toastr';
import useDynamicRefs from 'use-dynamic-refs';
import {fetchCollection, fetchDocument, updateDocument} from '../../state/api';

export const COLLECTION = 'message_threads';
export const COLLECTION_MESSAGES = 'messages';
export const COLLECTION_CONNECTIONS = 'connections';
export const COLLECTION_BLOCKED_USERS = 'blocked_users';

const Messages = () => {
	const {threadId} = useParams();
	const history = useHistory();
	const messagesEndRef = useRef();
	const [getRef, setRef] = useDynamicRefs();
	const [search, setSearch] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [selectedUser, setSelectedUser] = useState(null);
	const [unselectedUser, setUnSelectedUser] = useState(null);
	const [bloquedModal, setBloquedModal] = useState(false);
	const [unblockedModal, setUnblockedModal] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [isEmpty, setIsEmpty] = useState(true);
	const [messages, setMessages] = useState(null);
	const [copyMessages, setCopyMessages] = useState([]);
	const [connection, setConnection] = useState(null);
	const [reportDetails, setReportDetails] = useState([]);
	const [users, setUsers] = useState([]);
	const [threadData, setThreadData] = useState(null);

	const {userData} = useSelector(
		state => ({
			userData: state.auth.userData,
		}),
		shallowEqual,
	);

	const threadCollection = getFirestoreRef(COLLECTION).doc(threadId);
	const messagesCollection = threadCollection
		.collection(COLLECTION_MESSAGES)
		.orderBy('createdAt', 'desc')
		.limit(100);

	useDynamicLinks();
	useEffect(() => {
		handleFindConnectionInfo();
		const unsubscribeListener = messagesCollection.onSnapshot(querySnapshot => {
			if (querySnapshot) {
				renderMessages(querySnapshot.docs);
			}
		});

		return () => unsubscribeListener();
	}, []);

	useEffect(() => {
		const unsubscribeListener = threadCollection.onSnapshot(querySnapshot => {
			if (querySnapshot) {
				const doc = querySnapshot.data();
				setThreadData({
					...threadData,
					...doc,
				});
			}
		});

		return () => unsubscribeListener();
	}, []);

	const handleFindConnectionInfo = async () => {
		setIsLoading(true);
		const {connectionId} = (await threadCollection.get()).data();
		let reponse = null;
		let report = null;
		try {
			reponse = await fetchDocument('connections', connectionId);
			report = await fetchCollection('blocked_users', {
				queries: [
					{
						attribute: 'connectionId',
						operator: '==',
						value: connectionId,
					},
				],
			});
		} catch (e) {
			setIsLoading(false);
			return;
		}

		const usersArray = [];
		const userRequested = await fetchDocument('users', reponse.requestedUserId);
		const userRequestedBlocked = await fetchCollection('blocked_users', {
			queries: [
				{
					attribute: 'active',
					operator: '==',
					value: true,
				},
				{
					attribute: 'blockedUserId',
					operator: 'in',
					value: [reponse.requestedUserId],
				},
			],
		});
		const userRequesting = await fetchDocument(
			'users',
			reponse.requestingUserId,
		);
		const userRequestingBlocked = await fetchCollection('blocked_users', {
			queries: [
				{
					attribute: 'active',
					operator: '==',
					value: true,
				},
				{
					attribute: 'blockedUserId',
					operator: 'in',
					value: [reponse.requestedUserId, reponse.requestingUserId],
				},
			],
		});
		usersArray.push({...userRequested, blockedInfo: userRequestedBlocked[0]});
		usersArray.push({...userRequesting, blockedInfo: userRequestingBlocked[0]});
		setUsers(usersArray);
		setConnection(reponse);
		setReportDetails(report.filter(el => el?.active));
		setIsLoading(false);
	};

	const scrollToBottom = () => {
		messagesEndRef?.current?.scrollIntoView({behavior: 'smooth'});
	};

	const renderMessages = docs => {
		const temp = docs.map(doc => {
			const firebaseData = doc.data();

			const data = {
				_id: doc.id,
				text: '',
				createdAt: new Date().getTime(),
				...firebaseData,
			};

			if (!firebaseData.system) {
				data.user = {
					...firebaseData.user,
					name: firebaseData.user.displayName,
				};
			}
			return data;
		});

		// setLastMessage(temp[temp.length - 1]);
		makeMessagesData(temp);
	};

	const makeMessagesData = docs => {
		const templist = [];
		docs.forEach(doc => {
			templist.push(doc);
		});

		const clearMessages = [...new Set(templist)].reverse();
		setCopyMessages(clearMessages);
		setIsEmpty(!clearMessages.length);
		const grouped = useAgroupMessages(clearMessages);
		setMessages(grouped);
		setIsLoading(false);
		scrollToBottom();
	};

	const handleApplySearch = (query = '') => {
		if (!query.length) {
			makeMessagesData(copyMessages.reverse());
			scrollToBottom();
			return;
		}

		const templist = [];
		copyMessages.forEach(doc => {
			if (doc.text.toLowerCase().includes(query.toLowerCase())) {
				templist.push(doc);
			}
		});
		const clearMessages = [...new Set(templist)];
		const grouped = useAgroupMessages(clearMessages);
		setIsEmpty(!clearMessages.length);
		setMessages(grouped);
		setIsLoading(false);
	};

	const renderMessage = (msg, key) => {
		const positions = users.findIndex(el => el.id === msg.user._id);

		if (msg.system) {
			return (
				<Grid
					key={`messages-system-${key}-${msg._id}`}
					container
					justifyContent="center"
					alignItems="center"
					style={{display: 'grid'}}>
					<Grid
						item
						xs={12}
						style={{display: 'flex', justifyContent: 'center'}}>
						<Typography variant="caption" style={{color: colors.grey.medium}}>
							{msg.user.displayName} - {useFormatDateTime(msg.createdAt)}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{display: 'flex', justifyContent: 'center'}}>
						<Typography variant="caption" style={{color: colors.grey.medium}}>
							{msg.text}
						</Typography>
					</Grid>
				</Grid>
			);
		}

		if (positions === 0) {
			return (
				<Grid
					ref={setRef(msg._id)}
					key={`messages-right-${msg._id}`}
					container
					justifyContent="flex-end">
					<Grid
						item
						sx={{
							backgroundColor: colors.secondary.pink500,
							borderRadius: '20px 20px 0px 20px',
							padding: '5px 15px',
							minWidth: '25%',
						}}>
						<Grid item>
							<Typography style={{color: 'white'}}>{msg.text}</Typography>
						</Grid>

						<Grid container justifyContent="flex-start">
							<Grid item xs={12}>
								<Typography
									variant="caption"
									style={{color: 'white', textAlign: 'right'}}>
									{useFormatDateTime(msg.createdAt)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Typography variant="caption">{msg.user.name}</Typography>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid
				ref={setRef(msg._id)}
				key={`messages-left-${msg._id}`}
				container
				justifyContent="flex-start">
				<Grid item xs={12}>
					<Typography variant="caption">{msg.user.name}</Typography>
				</Grid>
				<Grid
					item
					sx={{
						backgroundColor: colors.grey.mediumLight,
						borderRadius: '0px 20px 20px 20px',
						padding: '5px 15px',
						minWidth: '25%',
					}}>
					<Grid item>
						<Typography>{msg.text}</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="caption">
							{useFormatDateTime(msg.createdAt)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const renderSystemMessage = (time, key) => {
		if (messages[time].length) {
			return (
				<Grid key={`messages-system-${key}-${time}`} container>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						style={{display: 'grid'}}>
						<Grid item xs={12}>
							<Typography variant="caption" style={{color: colors.grey.medium}}>
								{time}
							</Typography>
						</Grid>
					</Grid>
					{messages[time].map(renderMessage)}
				</Grid>
			);
		}
		return undefined;
	};

	const handleSelectUser = user => {
		setSelectedUser(user);
		setUnSelectedUser(
			users.find(el => el.system === undefined && user.id !== el.id),
		);
	};

	const handleClose = type => {
		if (type === 'navigate') {
			history.push(`/users-details/${selectedUser.id}`);
		}

		if (type === 'modal') {
			onHandleModal();
		}
	};

	const onHandleModal = () => {
		setBloquedModal(state => !state);
	};

	const onHandleUnblockedModal = () => {
		setUnblockedModal(state => !state);
	};

	const onHandleBlockUser = async () => {
		if (!feedback.length) {
			toastr.error('Please write a FeedBack');
			return;
		}

		setIsLoading(true);
		const threadInfo = (await threadCollection.get()).data();
		const connRef = getFirestoreRef(COLLECTION_CONNECTIONS).doc(
			threadInfo.connectionId,
		);
		await connRef.set(
			{
				status: {
					key: 3,
					name: 'Reported and blocked',
				},
				endedAt: new Date(),
			},
			{merge: true},
		);

		await threadCollection.set(
			{
				blocked: true,
				blockedByUserId: userData.id,
			},
			{merge: true},
		);

		await createDocument(COLLECTION_BLOCKED_USERS, null, {
			blockedByUserId: unselectedUser.id,
			blockedUserId: selectedUser.id,
			feedback,
			reason: null,
			subreason: null,
			other: null,
			blockedByUserAdminId: userData.id,
			active: true,
			createdAt: new Date(),
			threadId,
			connectionId: threadInfo.connectionId,
		});

		setIsLoading(false);
		onHandleModal();
		toastr.success('User has been blocked');
		window.location.reload();
	};

	const onHandleUnBlockUser = async () => {
		setIsLoading(true);
		await updateDocument('blocked_users', selectedUser.blockedInfo.id, {
			active: !selectedUser.blockedInfo.active,
		});
		onHandleUnblockedModal();
		setIsLoading(false);
		toastr.success('User has been unblocked');

		window.location.reload();
	};

	return (
		<>
			{bloquedModal && (
				<ConfirmationModal
					isActive={bloquedModal}
					isLoading={isLoading}
					confirmButtonMessage="Yes, block"
					title="Block the user"
					cancelButtonMessage="Cancel"
					onConfirmation={() => onHandleBlockUser()}
					onCancel={onHandleModal}>
					<Typography component="p">
						Are you sure you want to block
						<Typography component="span" style={{fontWeight: 'bold'}}>
							{' '}
							{selectedUser?.name}{' '}
						</Typography>
						from this conversation?
					</Typography>
					<Typography component="span">
						<Typography component="span" style={{fontWeight: 'bold'}}>
							{unselectedUser?.name}{' '}
						</Typography>
						will not be able to send any more messages or see
						<Typography component="span" style={{fontWeight: 'bold'}}>
							{' '}
							{selectedUser?.name}{' '}
						</Typography>
						information again.
					</Typography>

					<Box sx={{'& .MuiTextField-root': {mt: 1, width: '100%'}}}>
						<TextField
							required
							label="Notes"
							onChange={val => setFeedback(val.currentTarget.value)}
						/>
					</Box>
				</ConfirmationModal>
			)}

			{unblockedModal && (
				<ConfirmationModal
					isActive={unblockedModal}
					isLoading={isLoading}
					confirmButtonMessage="Yes, unblock"
					title="Unblock user"
					cancelButtonMessage="Cancel"
					onConfirmation={() => onHandleUnBlockUser()}
					onCancel={onHandleUnblockedModal}>
					<Typography component="span">
						Are you sure you want to remove
						<Typography component="span" style={{fontWeight: 'bold'}}>
							{' '}
							{selectedUser?.name}{' '}
						</Typography>
						from the
						<Typography component="span" style={{fontWeight: 'bold'}}>
							{' '}
							{unselectedUser?.name}{' '}
						</Typography>
						users blocked list.
					</Typography>
				</ConfirmationModal>
			)}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<Stack direction="row" alignItems="center" spacing={1}>
									<h1 className="title">Messages</h1>
									{connection ? (
										<Chip
											label={
												connection.status.key === 3
													? 'Reported and blocked'
													: connection.status.key === 1
													? 'Active'
													: 'Ended'
											}
											color={
												connection.status.key === 3
													? 'error'
													: connection.status.key === 1
													? 'success'
													: 'default'
											}
										/>
									) : undefined}
								</Stack>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Grid container spacing={2} className="section is-main-section">
				{isLoading ? (
					<Grid container justifyContent="center">
						<ClipLoader />
					</Grid>
				) : (
					<>
						<Grid
							item
							xs={12}
							md={6}
							style={{padding: 0, paddingLeft: 5, paddingRight: 15}}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography variant="h6">Members</Typography>
									{users.map((user, i) => {
										return (
											<Stack
												key={`user-member-${i}`}
												direction="row"
												alignItems="center"
												spacing={1}
												sx={{mb: 2}}>
												<Avatar>
													{user.name ? user.name.slice(0, 2) : null}
												</Avatar>
												<Stack>
													<Stack direction="row" alignItems="center">
														<Typography fontWeight="bold" sx={{mr: 1}}>
															{user.name}
														</Typography>
														{user?.bannedSystem ? (
															<Chip
																size="small"
																variant="outlined"
																color="error"
																label="Account disabled"
																sx={{mr: 1}}
															/>
														) : undefined}
														{user?.blockedInfo?.active ? (
															<>
																<Tooltip title="Unblock user">
																	<Chip
																		clickable
																		size="small"
																		color="error"
																		label="Blocked"
																		onClick={() => {
																			handleSelectUser(user);
																			onHandleUnblockedModal();
																		}}
																	/>
																</Tooltip>
															</>
														) : (
															<Tooltip title="Block user">
																<Chip
																	clickable
																	size="small"
																	color="success"
																	label="Active"
																	onClick={() => {
																		handleSelectUser(user);
																		handleClose('modal');
																	}}
																/>
															</Tooltip>
														)}

														<Tooltip title="Go to profile">
															<IconButton
																color="primary"
																onClick={() =>
																	history.push(`/users-details/${user.id}`)
																}>
																<VisibilityIcon />
															</IconButton>
														</Tooltip>
													</Stack>
													<Typography>{user.email}</Typography>
												</Stack>
											</Stack>
										);
									})}

									<Stack direction="row" alignItems="center" spacing={1}>
										<Chip
											label={`Created: ${
												useFormatDate(connection?.createdAt?.seconds * 1000) ||
												''
											}`}
										/>
										<Chip
											label={`Ended: ${
												connection?.endedAt
													? useFormatDate(connection?.endedAt?.seconds * 1000)
													: ''
											}`}
										/>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									{reportDetails && reportDetails.length ? (
										<>
											<Typography variant="h6">Reports details</Typography>
											{reportDetails.map((rep, i) => {
												const userBanned = users.find(
													el => el?.id === rep?.blockedUserId,
												);
												const user = users.find(
													el => el?.id === rep?.blockedByUserId,
												);
												return (
													<Stack
														key={`user-reports-${i}`}
														spacing={1}
														sx={{mb: 1, borderRadius: '5px', padding: '5px'}}>
														<Typography component="span">
															{userBanned.name} has been reported and blocked
															from communicating with {user.name}
														</Typography>

														<Stack spacing={1} sx={{padding: '0 10px'}}>
															<TextField
																fullWidth
																disabled
																label="Reason"
																multiline
																rows={2}
																defaultValue={`${rep?.reason || ''} ${
																	rep?.subreason || ''
																} ${rep?.other || ''} ${rep?.feedback || ''}`}
															/>
														</Stack>
														<Grid item xs={12} md={4}>
															<Chip
																label={`Blocked on: ${
																	useFormatDate(
																		rep?.createdAt?.seconds * 1000,
																	) || ''
																}`}
															/>
														</Grid>
													</Stack>
												);
											})}
										</>
									) : undefined}
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								border: '1px solid lightgrey',
								borderRadius: '5px',
								padding: '5px',
							}}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									size="small"
									placeholder="Search…"
									value={search}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													disabled={!search.length}
													color="primary"
													onClick={() => {
														setSearch('');
														handleApplySearch('');
													}}>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
									onChange={val => {
										const text = val.target.value;
										setSearch(text);
										handleApplySearch(text);
									}}
								/>
							</Grid>
							<Box
								sx={{
									height: 'calc(100vh - 420px)',
									marginLeft: 'auto',
									marginRight: 'auto',
									overflow: 'auto',
									borderWidth: '1px',
								}}>
								{messages && !isEmpty ? (
									Object.keys(messages).reverse().map(renderSystemMessage)
								) : (
									<Grid container justifyContent="center">
										<Typography>
											{search.length
												? `No messages were found that include ${search}`
												: 'No messages yet'}
										</Typography>
									</Grid>
								)}
								<div ref={messagesEndRef} />
							</Box>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default Messages;
