/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {createAction} from 'redux-act';
import {toastr} from 'react-redux-toastr';
import {firebaseError} from 'utils';
import firebase, {getFunctions} from 'firebase.js';
import {checkUserData, AUTH_UPDATE_USER_DATA} from './auth';
import {fetchCollection, fetchDocument, updateDocument} from '../api';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
	'USERS_FETCH_DATA_SUCCESS',
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
	'USERS_DELETE_USER_SUCCESS',
);
export const USERS_ENABLE_USER_ACCOUNT_SUCCESS = createAction(
	'USERS_ENABLE_USER_ACCOUNT_SUCCESS',
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
	'USERS_CREATE_USER_SUCCESS',
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
	'USERS_MODIFY_USER_SUCCESS',
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');
export const USERS_SUCCESS_FETCH = createAction('USERS_SUCCESS_FETCH');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers = (userId = '') => {
	return async (dispatch, getState) => {
		dispatch(checkUserData());

		dispatch(USERS_FETCH_DATA_INIT());

		if (userId) {
			let user;
			try {
				user = await fetchDocument('users', userId);
			} catch (error) {
				toastr.error('', error);
				return dispatch(USERS_FETCH_DATA_FAIL({error}));
			}

			if (!user) {
				const errorMessage = 'User not available';
				toastr.error('', errorMessage);
				return dispatch(USERS_FETCH_DATA_FAIL({error: errorMessage}));
			}
			const users = getState().users.data;
			users.push(user);

			return dispatch(
				USERS_FETCH_DATA_SUCCESS({
					data: users,
				}),
			);
		}

		let users;
		let reportsAndBlocks;

		try {
			users = await fetchCollection('users');
			reportsAndBlocks = await fetchCollection('blocked_users', {
				queries: [
					{
						attribute: 'active',
						operator: '==',
						value: true,
					},
				],
			});
		} catch (error) {
			toastr.error('', error);
			return dispatch(USERS_FETCH_DATA_FAIL({error}));
		}

		const newArray = [];

		for (const user of users) {
			const counts = reportsAndBlocks.filter(
				el => el.blockedUserId === user.id,
			);
			newArray.push({...user, reportsCounts: counts.length});
		}

		return dispatch(
			USERS_FETCH_DATA_SUCCESS({
				data: newArray,
			}),
		);
	};
};

export const deleteUser = id => {
	return async (dispatch, getState) => {
		dispatch(USERS_DELETE_USER_INIT());
		const {locale} = getState().preferences;

		let response;
		try {
			const userAPI = getFunctions().httpsCallable(
				'app/api/users/disabledAccount',
			);
			response = await userAPI({userId: id});
		} catch (error) {
			const errorMessage = firebaseError(error.message, locale);
			toastr.error('', errorMessage);
			return dispatch(
				USERS_CREATE_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'Blocked successfully');
		return dispatch(USERS_ENABLE_USER_ACCOUNT_SUCCESS({id}));
	};
};

export const enableUserAccount = id => {
	return async (dispatch, getState) => {
		dispatch(USERS_DELETE_USER_INIT());
		const {locale} = getState().preferences;

		let response;
		try {
			const userAPI = getFunctions().httpsCallable(
				'app/api/users/enableAccount',
			);
			response = await userAPI({userId: id});
		} catch (error) {
			const errorMessage = firebaseError(error.message, locale);
			toastr.error('', errorMessage);
			return dispatch(
				USERS_CREATE_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'Unblocked Successfully');
		return dispatch(USERS_DELETE_USER_SUCCESS({id}));
	};
};

export const clearUsersDataLogout = () => {
	return dispatch => {
		dispatch(USERS_CLEAR_DATA_LOGOUT());
	};
};

export const createUser = ({name, email, location, isAdmin, role}) => {
	return async (dispatch, getState) => {
		dispatch(USERS_CREATE_USER_INIT());
		const {locale} = getState().preferences;

		const existWihtThisEmail = getState().users.data.find(
			user => user.email === email,
		);
		if (existWihtThisEmail) {
			toastr.error('', 'There is already an user for this email address');
			return dispatch(
				USERS_CREATE_USER_FAIL({
					error: 'There is already an user for this email address',
				}),
			);
		}

		let response;
		try {
			const createUserAuth = getFunctions().httpsCallable(
				'app/api/users/create',
			);
			response = await createUserAuth({
				email,
				name,
				is_admin: isAdmin,
				role,
				location,
				password: 'nopassword',
			});
		} catch (error) {
			const errorMessage = firebaseError(error.message, locale);
			toastr.error('', errorMessage);
			return dispatch(
				USERS_CREATE_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'User created successfully');
		return dispatch(USERS_CREATE_USER_SUCCESS({user: response.data}));
	};
};

export const updateUser = ({userId, isAdmin}) => {
	return async (dispatch, getState) => {
		dispatch(USERS_DELETE_USER_INIT());
		const {locale} = getState().preferences;
		// const user = getState().users.data.find(thisUser => thisUser.id === userId);
		let response;
		try {
			const userAPI = getFunctions().httpsCallable('app/api/users/update');
			response = await userAPI({
				userId,
				isAdmin,
			});
		} catch (error) {
			const errorMessage = firebaseError(error.message, locale);
			toastr.error('', errorMessage);
			return;
		}

		toastr.success('', 'This user has been updated successfully');
		return dispatch(USERS_ENABLE_USER_ACCOUNT_SUCCESS({id: userId}));
	};
};

export const modifyUser = ({
	name,
	location,
	isAdmin,
	createdAt,
	id,
	isEditing,
	isProfile,
}) => {
	return async (dispatch, getState) => {
		dispatch(USERS_MODIFY_USER_INIT());
		const {locale} = getState().preferences;
		const user = isProfile
			? getState().auth.userData
			: getState().users.data.find(thisUser => thisUser.id === id);
		let deleteLogoTask;
		let uploadLogoTask;

		const userData = {
			name,
			location,
			createdAt,
			isAdmin: isAdmin || user.isAdmin || user.is_admin,
		};
		const updateUserDbTask = updateDocument('users', id, userData);

		try {
			await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				USERS_MODIFY_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		const {uid} = firebase.auth().currentUser;

		if (id === uid) {
			dispatch(AUTH_UPDATE_USER_DATA({...userData, id}));
		}

		if (isProfile) {
			toastr.success('', 'Profile updated successfully');
		} else if (isEditing) {
			toastr.success('', 'User updated successfully');
		}

		return dispatch(USERS_SUCCESS_FETCH());
	};
};

export const updateUserProfile = ({
	id,
	name,
	phone,
	shortBio,
	ounces,
	role,
	location,
	dietaryIntake,
	others,
	email,
	changeEmail,
	babyAge,
}) => {
	return async (dispatch, getState) => {
		dispatch(USERS_MODIFY_USER_INIT());

		const {locale} = getState().preferences;

		if (changeEmail) {
			const userAPI = getFunctions().httpsCallable('app/api/users/updateEmail');
			await userAPI({
				userId: id,
				email,
			});
		}

		const userData = {
			name,
			phone,
			shortBio,
			ounces,
			role,
			location,
			dietaryIntake,
			others,
			babyAge,
		};
		const updateUserDbTask = updateDocument('users', id, userData);

		try {
			await Promise.all([updateUserDbTask]);
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				USERS_MODIFY_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'User updated successfully');
		window.location.reload();
	};
};

export const usersCleanUp = () => dispatch => dispatch(USERS_CLEAN_UP());
