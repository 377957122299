import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import {permissions} from './config/configuration';
import {env} from './config/env';

firebase.initializeApp(permissions.firebase);
firebase.firestore();
firebase.storage();
firebase.auth();

export default firebase;

export const getFunctions = () => {
	const functions = firebase.functions();

	if (env === 'local') {
		functions.useEmulator(
			'localhost',
			permissions.REACT_APP_FIRE_BASE_FUNCTIONS_PORT,
		);
	}

	return functions;
};
