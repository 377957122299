/* eslint-disable no-useless-escape */
import moment from 'moment';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

const useChangeHandler = setState => {
	const onChangeHandler = event => {
		const {target} = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const {name} = target;
		setState(prevState => ({...prevState, [`${name}`]: value}));
	};

	return onChangeHandler;
};

const useFormatMessage = (
	id,
	values = {},
	defaultMessage = '',
	description = '',
) => {
	const intl = useIntl();
	return intl.formatMessage({id, defaultMessage, description}, values);
};

const useFormatDate = (value, options = {}) => {
	const intl = useIntl();
	return intl.formatDate(value, {
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		...options,
	});
};

const useFormatDateTime = (date = new Date()) => {
	return moment(date).format('HH:mm A');
};

const useDynamicLinks = () => {
	const query = new URLSearchParams(useLocation().search);
	if (query.toString().includes('link=')) {
		window.location.href = `/link/?${query}`;
	}
};

const getToday = (format = 'YYYY-MM-DD') => {
	return new Date();
};

const formattedDate = (date = new Date(), format = 'YYYY-MM-DD') => {
	return moment(date).format(format);
};

const useAgroupMessages = messages => {
	const currentDate = getToday(); // Or leave out the argument for actual date
	currentDate.setHours(0, 0, 0, 0); // set to midnight
	// Prepare all related dates: yesterday and last Monday
	const keys = [];
	keys.push(['Today', new Date(currentDate)]); // clone
	currentDate.setDate(currentDate.getDate() - 1);
	keys.push(['Yesterday', new Date(currentDate)]); // clone
	currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));
	keys.push(['This week', new Date(currentDate)]); // clone

	const messageTypeList = {
		Today: [],
		Yesterday: [],
		'This week': [],
	};

	messages.forEach(message => {
		const selectedDate = formattedDate(message.createdAt)
			.substring(0, 10)
			.replace(/-/g, '/');
		const messageDate = new Date(selectedDate);
		const [key] = keys.find(([_, date]) => messageDate >= date) || [];
		if (key) messageTypeList[key].push(message);
	});

	return messageTypeList;
};

export {
	useChangeHandler,
	useFormatMessage,
	useFormatDate,
	useDynamicLinks,
	useFormatDateTime,
	useAgroupMessages,
};
