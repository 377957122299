const colors = {
	primary: {
		blue500: '#034E59',
		blue400: '#006D77',
		blue300: '#2B7B87',
		blue200: '#5C949C',
		blue100: '#84BAC1',
		gradient: ['#2B7B87', '#034E59'],
	},

	secondary: {
		pink500: '#FC6681',
		pink400: '#F98499',
		pink300: '#FFA5B5',
		pink200: '#FDC0CB',
		pink100: '#FFD6DD',
		gradient: ['#FC6681', '#F73343'],
	},

	grey: {
		dark: '#3F3F3F',
		mediumDark: '#707070',
		medium: '#CCCCCC',
		mediumLight: '#E8E8E8',
		light: '#F4F4F4',
	},

	highlight: {
		lightYellow: '#FDEBB8',
		mediumYellow: '#FCE49C',
		darkYellow: '#FBD872',
		darkOrange: '#EB6410',
		lightOrange: '#FFA68C',
	},

	loader: {
		areola: '#EBA296',
		nipple: '#D18072',
		combinationOne: ['#D18072', '#8A5147'],
		combinationTwo: ['#FBE5E1', '#EFD8AA'],
	},

	white: '#FFFFFF',
	halfBlack: '#232323',
	black: '#000000',

	transparentWhite: 'rgba(255, 255, 255, 0.2)',
	semiTransparentWhite: 'rgba(255, 255, 255, 0.9)',
	semiTransparentBlack: 'rgba(0, 0, 0, 0.5)',

	transparentPink: 'rgba(252, 102, 129, 0.2)',
	transparentBlue: 'rgba(3, 78, 89, 0.2);',

	error: '#F44336',
	success: '#19C18F',
};

export default colors;
