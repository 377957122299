import React, {useState} from 'react';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';

import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logout} from 'state/actions/auth';
import paths from 'pages/Router/paths';

import {
	ManageAccountsOutlined,
	Logout as LogoutIcon,
	MenuOpen as MenuOpenIcon,
	Menu as MenuIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const AppBarCustom = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const AppBar = ({open, handleDrawerOpen}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [anchorElUser, setAnchorElUser] = useState(null);

	const {userName} = useSelector(
		state => ({
			userName: state.auth.userData.name,
		}),
		shallowEqual,
	);

	const handleOpenUserMenu = event => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onClickLogoutHandler = () => {
		dispatch(logout());
		handleCloseUserMenu();
	};

	const onHanlderNaviogate = () => {
		history.push(paths.PROFILE);
		handleCloseUserMenu();
	};

	return (
		<AppBarCustom position="fixed" open={open}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					sx={{
						marginRight: 5,
						...(open && {display: 'none'}),
					}}>
					<MenuOpenIcon />
				</IconButton>

				<Typography
					variant="h6"
					noWrap
					component="div"
					sx={{display: {xs: 'none', md: 'flex', lg: 'flex'}}}>
					The Drop Milk Share (Admin panel)
				</Typography>

				<Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'flex'}}} />
				<Box sx={{flexGrow: 0}}>
					<Tooltip title="Open settings" sx={{mr: 10}}>
						<IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
							<Box
								sx={{
									display: {xs: 'none', md: 'flex', lg: 'flex'},
									alignItems: 'center',
								}}>
								<Avatar alt={userName} sizes="small" />
								<Typography
									sx={{ml: 1, mr: 1, color: 'white', fontWeight: 'bold'}}>
									{userName}
								</Typography>
							</Box>
							<MenuIcon color="secondary" />
						</IconButton>
					</Tooltip>

					<Menu
						sx={{mt: '45px'}}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}>
						<MenuItem onClick={onHanlderNaviogate}>
							<ManageAccountsOutlined sx={{mr: 2}} />
							<Typography textAlign="center">Profile</Typography>
						</MenuItem>
						<MenuItem onClick={onClickLogoutHandler}>
							<LogoutIcon sx={{mr: 2}} />
							<Typography textAlign="center">Logout</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBarCustom>
	);
};

export default AppBar;
