/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';

import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {useFormatMessage, useFormatDate, useDynamicLinks} from 'hooks';
import Table from 'components/Table';
import {Chat as ChatIcon} from '@mui/icons-material';
import {IconButton, Stack, Chip, Typography, Avatar} from '@mui/material';
import classes from '../Users/Users.module.scss';
import {fetchCollection, fetchDocument} from '../../state/api';

const Connections = () => {
	const {id} = useParams();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [connections, setConnections] = useState([]);
	const [search, setSearch] = useState('');
	const {userData} = useSelector(
		state => ({
			userData: state.users.data.find(user => user.id === id),
		}),
		shallowEqual,
	);

	useDynamicLinks();

	useEffect(() => {
		handleFetchConnections();
	}, []);

	const handleFetchConnections = async () => {
		setIsLoading(true);
		let reponse = null;
		let threads = null;
		try {
			reponse = (await fetchCollection('connections')).filter(
				el => el.requestedUserId === id || el.requestingUserId === id,
			);
			threads = await fetchCollection('message_threads');
		} catch (e) {
			console.log(e);
			setIsLoading(false);
			return;
		}

		const filteredArray = [];
		for (const conn of reponse) {
			const user = await fetchDocument(
				'users',
				id === conn.requestedUserId
					? conn.requestingUserId
					: conn.requestedUserId,
			);
			const connThread = threads.find(el => el.connectionId === conn?.id);

			filteredArray.push({
				...conn,
				name: user.name,
				email: user.email,
				status: conn.status.name,
				statusId: conn.status.key,
				threadId: connThread?.id,
			});
		}
		setConnections(filteredArray);
		setIsLoading(false);
	};

	const columns = [
		{
			Header: 'User',
			accessor: 'userdetails',
			Cell: ({row}) => (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Avatar sx={{display: {xs: 'none'}}}>
						{row.original.name ? row.original.name.slice(0, 2) : null}
					</Avatar>
					<Stack>
						<Typography fontWeight="bold">{row.original.name}</Typography>
						<Typography>{row.original.email}</Typography>
					</Stack>
				</Stack>
			),
		},
		{
			Header: useFormatMessage('Users.created'),
			accessor: 'created',
			disableSortBy: true,
			Cell: ({row}) => (
				<Stack>
					{row.original.createdAt
						? useFormatDate(row.original.createdAt?.seconds * 1000)
						: ''}
				</Stack>
			),
		},
		{
			Header: 'Ended',
			accessor: 'ended',
			disableSortBy: true,
			Cell: ({row}) => (
				<Stack>
					{row.original.endedAt
						? useFormatDate(row.original.endedAt?.seconds * 1000)
						: ''}
				</Stack>
			),
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Status</div>,
			accessor: 'status',
			disableSortBy: true,
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<Chip
						label={row.original.status}
						color={
							row.original.statusId === 0
								? 'info'
								: row.original.statusId === 1
								? 'success'
								: row.original.statusId === 3
								? 'error'
								: 'default'
						}
					/>
				</Stack>
			),
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Messages</div>,
			id: 'actions',
			accessor: 'actions',
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<IconButton
						color="primary"
						omponent="span"
						disabled={!row.original.threadId}
						onClick={() =>
							history.push(`/connection-messages/${row.original.threadId}`)
						}>
						<ChatIcon />
					</IconButton>
				</Stack>
			),
			disableSortBy: true,
		},
	];

	const data = search
		? connections.filter(el => {
				const clonedElem = {name: el.requestedBy, email: el.email};
				return Object.values(clonedElem).some(field => {
					if (field !== null)
						return field.toLowerCase().includes(search.toLowerCase());
					return false;
				});
		  })
		: connections;

	return (
		<>
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<h1 className="title">Connections</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section is-main-section" style={{minHeight: 0}}>
				<div className="card has-table has-mobile-sort-spaced">
					<header className="card-header">
						<p className={classNames('card-header-title', classes.tableHeader)}>
							<span>{useFormatMessage('Users.search')}</span>
							<input
								type="text"
								className="input"
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</p>
					</header>
					<div className="b-table">
						{isLoading ? (
							<ClipLoader />
						) : (
							<Table columns={columns} data={data} />
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default Connections;
