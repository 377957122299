import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {ThemeProvider} from '@mui/material/styles';
import {configureStore} from './state/store';
import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

import './assets/css/main.css';
import theme from './styles/theme';

const {store, persistor} = configureStore({});

const app = (
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<LanguageWrapper>
					<PersistGate persistor={persistor}>
						<ReduxToastr
							newestOnTop={false}
							preventDuplicates
							position="bottom-right"
							getState={state => state.toastr}
							transitionIn="fadeIn"
							transitionOut="fadeOut"
							progressBar
							closeOnToastrClick
						/>
						<Router />
					</PersistGate>
				</LanguageWrapper>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
