import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppBar from 'components/Navigation/NavBar';
import {
	Group as GruopIcon,
	AddLink as AddLinkIcon,
	Home as HomeIcon,
	GroupAdd as GroupAddIcon,
} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import paths from 'pages/Router/paths';
import Footer from '../Navigation/Footer';

const drawerWidth = 240;

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
	({theme, open}) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

const Layout = ({children}) => {
	const theme = useTheme();
	const history = useHistory();
	const [open, setOpen] = useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleNavigator = path => {
		history.replace(path);
	};

	return (
		<Box sx={{display: 'flex'}}>
			<CssBaseline />
			<AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<Typography>The Drop (Admin panel)</Typography>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<ListItem
						disablePadding
						sx={{display: 'block'}}
						onClick={() => handleNavigator(paths.ROOT)}>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText primary="Home" sx={{opacity: open ? 1 : 0}} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						sx={{display: 'block'}}
						onClick={() => handleNavigator(paths.USERS)}>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}>
								<GruopIcon />
							</ListItemIcon>
							<ListItemText primary="Users" sx={{opacity: open ? 1 : 0}} />
						</ListItemButton>
					</ListItem>
					<ListItem
						disablePadding
						sx={{display: 'block'}}
						onClick={() => handleNavigator(paths.INVITE_CODES)}>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}>
								<GroupAddIcon />
							</ListItemIcon>
							<ListItemText
								primary="Invite Codes"
								sx={{opacity: open ? 1 : 0}}
							/>
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					height: 'calc(100vh - 68px);',
					backgroundColor: 'white',
				}}>
				<DrawerHeader />
				{children}
				<Footer />
			</Box>
		</Box>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
