import {createAction} from 'redux-act';
import {toastr} from 'react-redux-toastr';

import {firebaseError} from 'utils';
import firebase, {getFunctions} from 'firebase.js';

import {checkUserData} from './auth';
import {fetchCollection, fetchDocument, updateDocument} from '../api';

export const INVITE_CODES_FETCH_DATA_INIT = createAction(
	'INVITE_CODES_FETCH_DATA_INIT',
);
export const INVITE_CODES_FETCH_DATA_SUCCESS = createAction(
	'INVITE_CODES_FETCH_DATA_SUCCESS',
);
export const INVITE_CODES_FETCH_DATA_FAIL = createAction(
	'INVITE_CODES_FETCH_DATA_FAIL',
);

export const INVITE_CODES_DELETE_USER_INIT = createAction(
	'INVITE_CODES_DELETE_USER_INIT',
);
export const INVITE_CODES_DELETE_USER_SUCCESS = createAction(
	'INVITE_CODES_DELETE_USER_SUCCESS',
);
export const INVITE_CODES_DELETE_USER_FAIL = createAction(
	'INVITE_CODES_DELETE_USER_FAIL',
);

export const INVITE_CODES_CREATE_USER_INIT = createAction(
	'INVITE_CODES_CREATE_USER_INIT',
);
export const INVITE_CODES_CREATE_USER_SUCCESS = createAction(
	'INVITE_CODES_CREATE_USER_SUCCESS',
);
export const INVITE_CODES_CREATE_USER_FAIL = createAction(
	'INVITE_CODES_CREATE_USER_FAIL',
);

export const INVITE_CODES_MODIFY_USER_INIT = createAction(
	'INVITE_CODES_MODIFY_USER_INIT',
);
export const INVITE_CODES_MODIFY_USER_SUCCESS = createAction(
	'INVITE_CODES_MODIFY_USER_SUCCESS',
);
export const INVITE_CODES_MODIFY_USER_FAIL = createAction(
	'INVITE_CODES_MODIFY_USER_FAIL',
);

export const INVITE_CODES_CLEAN_UP = createAction('INVITE_CODES_CLEAN_UP');

export const INVITE_CODES_CLEAR_DATA_LOGOUT = createAction(
	'INVITE_CODES_CLEAR_DATA_LOGOUT',
);

const COLLECTION_NAME = 'invite_codes';

export const fetchInviteCodes = (id = '') => {
	return async (dispatch, getState) => {
		dispatch(checkUserData());

		dispatch(INVITE_CODES_FETCH_DATA_INIT());

		if (id) {
			let inviteCode;
			try {
				inviteCode = await fetchDocument(COLLECTION_NAME, id);
			} catch (error) {
				toastr.error('', error);
				return dispatch(INVITE_CODES_FETCH_DATA_FAIL({error}));
			}

			if (!inviteCode) {
				const errorMessage = 'Invite code not available';
				toastr.error('', errorMessage);
				return dispatch(INVITE_CODES_FETCH_DATA_FAIL({error: errorMessage}));
			}
			const inviteCodes = getState().inviteCodes.data;
			inviteCodes.push(inviteCode);

			return dispatch(
				INVITE_CODES_FETCH_DATA_SUCCESS({
					data: inviteCodes,
				}),
			);
		}

		let inviteCodes;

		try {
			const resp = await fetchCollection(COLLECTION_NAME);
			inviteCodes = resp.map(i => {
				const userCreator = getState().users.data.find(
					user => user.id === i.created_by,
				);

				return {
					...i,
					created_by: userCreator ? userCreator.email : 'System administrator',
				};
			});
		} catch (error) {
			toastr.error('', error);
			return dispatch(INVITE_CODES_FETCH_DATA_FAIL({error}));
		}

		return dispatch(
			INVITE_CODES_FETCH_DATA_SUCCESS({
				data: inviteCodes,
			}),
		);
	};
};

export const deleteInivteCode = id => {
	return async (dispatch, getState) => {
		dispatch(INVITE_CODES_DELETE_USER_INIT());
		const {locale} = getState().preferences;

		const inviteCode = getState().inviteCodes.data.find(
			thisInviteCode => thisInviteCode.id === id,
		);
		const inviteCodeData = {
			...inviteCode,
			deleted: !inviteCode.deleted,
		};
		const updateUserDbTask = updateDocument(
			COLLECTION_NAME,
			id,
			inviteCodeData,
		);

		try {
			await Promise.all([updateUserDbTask]);
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				INVITE_CODES_MODIFY_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		let inviteCodes;

		try {
			inviteCodes = await fetchCollection(COLLECTION_NAME);
		} catch (error) {
			toastr.error('', error);
			return dispatch(INVITE_CODES_FETCH_DATA_FAIL({error}));
		}

		toastr.success('', 'Invite code updated successfully');
		return dispatch(
			INVITE_CODES_FETCH_DATA_SUCCESS({
				data: inviteCodes,
			}),
		);
	};
};

export const clearInviteCodesDataLogout = () => {
	return dispatch => {
		dispatch(INVITE_CODES_CLEAR_DATA_LOGOUT());
	};
};

export const createInviteCode = ({email, inviteCode, role}) => {
	return async (dispatch, getState) => {
		dispatch(INVITE_CODES_CREATE_USER_INIT());
		const {locale} = getState().preferences;
		const existWihtThisEmail = getState().inviteCodes.data.find(
			thisInviteCode => thisInviteCode.email === email,
		);
		if (existWihtThisEmail) {
			toastr.error(
				'',
				'There is already an invitation code for this email address',
			);
			return dispatch(
				INVITE_CODES_CREATE_USER_FAIL({
					error: 'There is already an invitation code for this email address',
				}),
			);
		}

		const {uid} = firebase.auth().currentUser;

		const createInviteCodeFunction = getFunctions().httpsCallable(
			'app/api/invite-codes/generate-new-and-send',
		);

		const inviteCodeData = {
			email,
			invite_code: inviteCode,
			role,
			deleted: false,
			created_at: new Date(),
			created_by: uid,
		};

		try {
			await createInviteCodeFunction({
				email,
				role,
				code: inviteCode,
			});
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				INVITE_CODES_CREATE_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'Invite code created successfully');
		return dispatch(INVITE_CODES_CREATE_USER_SUCCESS({user: inviteCodeData}));
	};
};

export const modifyUser = ({id, deleted}) => {
	return async (dispatch, getState) => {
		dispatch(INVITE_CODES_MODIFY_USER_INIT());
		const {locale} = getState().preferences;
		const inviteCode = getState().inviteCodes.data.find(
			thisInviteCode => thisInviteCode.id === id,
		);
		const inviteCodeData = {
			...inviteCode,
			deleted: deleted || deleted === false ? deleted : inviteCode.deleted,
		};
		const updateUserDbTask = updateDocument(
			COLLECTION_NAME,
			id,
			inviteCodeData,
		);

		try {
			await Promise.all([updateUserDbTask]);
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				INVITE_CODES_MODIFY_USER_FAIL({
					error: errorMessage,
				}),
			);
		}

		toastr.success('', 'Invite code updated successfully');
		return dispatch(
			INVITE_CODES_MODIFY_USER_SUCCESS({user: inviteCodeData, id}),
		);
	};
};

export const inviteCodeCleanUp = () => dispatch =>
	dispatch(INVITE_CODES_CLEAN_UP());
