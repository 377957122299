/* eslint-disable no-self-compare */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {shallowEqual, useSelector} from 'react-redux';
import {updateDocument} from 'state/api/firestore';
import {Stack, Typography, Avatar, IconButton, Tooltip} from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import {useFormatMessage, useDynamicLinks, useFormatDate} from 'hooks';
import {
	Block as BlockIcon,
	Chat as ChatIcon,
	Restore as RestoreIcon,
} from '@mui/icons-material';
import Table from 'components/Table';
import classes from '../Users/Users.module.scss';
import {fetchCollection, fetchDocument} from '../../state/api';

const BlockedUsers = () => {
	const {id} = useParams();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [connections, setConnections] = useState([]);
	const [userSelected, setUserSelected] = useState(null);
	const [search, setSearch] = useState('');
	const [blockedModal, setBlockedModal] = useState({
		userId: null,
		isOpen: false,
		active: null,
	});

	const {userData} = useSelector(
		state => ({
			userData: state.users.data.find(user => user.id === id),
		}),
		shallowEqual,
	);

	useDynamicLinks();
	useEffect(() => {
		handleFindBlockedUsers();
	}, []);

	const handleFindBlockedUsers = async () => {
		setIsLoading(true);
		let reponse = null;
		try {
			reponse = await fetchCollection('blocked_users', {
				queries: [
					{
						attribute: 'blockedByUserId',
						operator: '==',
						value: id,
					},
				],
			});
		} catch (e) {
			setIsLoading(false);
			return;
		}

		const filteredArray = [];
		for (const item of reponse) {
			if (item.active) {
				const user = await fetchDocument('users', item.blockedUserId);

				filteredArray.push({
					...item,
					...item.reportDetails,
					userId: user.id,
					email: user.email,
					name: user.name,
				});
			}
		}
		setConnections(filteredArray.sort((a, b) => a?.active - b?.active));
		setIsLoading(false);
	};

	const onHandleBlockedModal = (
		id = null,
		close = null,
		active = null,
		user = null,
	) => {
		setBlockedModal(prevState => ({
			userId: id,
			isOpen: close != null ? false : !prevState.isOpen,
			active,
		}));
		setUserSelected(user);
	};

	const columns = [
		{
			Header: 'User',
			accessor: 'userdetails',
			Cell: ({row}) => (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Avatar>
						{row.original.name ? row.original.name.slice(0, 2) : null}
					</Avatar>
					<Stack>
						<Typography fontWeight="bold">{row.original.name}</Typography>
						<Typography>{row.original.email}</Typography>
					</Stack>
				</Stack>
			),
		},
		{
			Header: 'Reason',
			accessor: 'reason',
		},
		{
			Header: 'Subreason',
			accessor: 'subreason',
		},
		{
			Header: 'Feedback',
			accessor: 'feedback',
		},
		{
			Header: 'Blocked on',
			accessor: 'created',
			Cell: ({row}) => (
				<small className="has-text-grey is-abbr-like">
					{row.original.createdAt
						? useFormatDate(row.original.createdAt?.seconds * 1000)
						: ''}
				</small>
			),
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Messages</div>,
			id: 'actions',
			accessor: 'actions',
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<Tooltip
						title={!row.original?.active ? 'Block user' : 'Unblock user'}>
						<IconButton
							color="warning"
							omponent="span"
							disabled={!row.original?.active}
							onClick={() =>
								onHandleBlockedModal(
									row.original.userId,
									null,
									false,
									row.original,
								)
							}>
							{!row.original?.active ? <BlockIcon /> : <RestoreIcon />}
						</IconButton>
					</Tooltip>

					<IconButton
						color="primary"
						omponent="span"
						disabled={!row.original.threadId}
						onClick={() =>
							history.push(`/connection-messages/${row.original.threadId}`)
						}>
						<ChatIcon />
					</IconButton>
				</Stack>
			),
			disableSortBy: true,
		},
	];

	const onHandleSetAdminPermissions = async () => {
		setIsLoading(true);
		await updateDocument('blocked_users', userSelected.id, {
			active: !userSelected.active,
		});
		onHandleBlockedModal(null, false, null);
		setIsLoading(false);
		window.location.reload();
	};

	const data = search
		? connections.filter(el => {
				const clonedElem = {name: el.requestedBy, email: el.email};
				return Object.values(clonedElem).some(field => {
					if (field !== null)
						return field.toLowerCase().includes(search.toLowerCase());
					return false;
				});
		  })
		: connections;

	return (
		<>
			{blockedModal.isOpen && (
				<ConfirmationModal
					isActive={blockedModal.isOpen}
					isLoading={isLoading}
					confirmButtonMessage="Confirm"
					title="Blocked users"
					body={
						!blockedModal?.active
							? `Are you sure you want to remove ${userSelected?.name} from the ${userData?.name} users blocked list, this can be undone at any time?`
							: `Are you sure you want to add ${userSelected?.name} to the ${userData?.name} users blocked list, this can be undone at any time?`
					}
					cancelButtonMessage="Cancel"
					onConfirmation={onHandleSetAdminPermissions}
					onCancel={() => onHandleBlockedModal(null, false, null)}
				/>
			)}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<h1 className="title">Blocked users</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section is-main-section">
				<div className="card has-table has-mobile-sort-spaced">
					<header className="card-header">
						<p className={classNames('card-header-title', classes.tableHeader)}>
							<span>{useFormatMessage('Users.search')}</span>
							<input
								type="text"
								className="input"
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</p>
					</header>
					<div className="b-table">
						{isLoading ? (
							<ClipLoader />
						) : (
							<Table columns={columns} data={data} />
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default BlockedUsers;
