/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {yupResolver} from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import {usersCleanUp} from 'state/actions/users';
import {useFormatMessage} from 'hooks';
import ErrorMessage from 'components/ErrorMessage';

import './UserForm.scss';

const UserForm = ({isEditing, isProfile, user, onSubmitHandler, schema}) => {
	const {loading, success} = useSelector(
		state => ({
			loading: state.inviteCodes.loading,
			success: state.inviteCodes.success,
		}),
		shallowEqual,
	);

	const dispatch = useDispatch();

	const {register, handleSubmit, errors, setValue} = useForm({
		defaultValues: {...user},
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (success) {
			setValue('file', null);
		}
		return () => dispatch(usersCleanUp());
	}, [dispatch, success, setValue]);

	const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

	const goBackMessage = useFormatMessage('UserForm.goBack');

	const emailMessage = useFormatMessage('UserForm.email');

	const donorMessage = useFormatMessage('InviteCodeForm.donor');
	const recipientMessage = useFormatMessage('InviteCodeForm.recipient');

	return (
		<>
			<div className="tile is-ancestor">
				<div className="tile is-parent">
					<div className="card tile is-child">
						<header className="card-header">
							<p className="card-header-title">
								<span className="icon">
									<i className="mdi mdi-account-edit default" />
								</span>
								{useFormatMessage('InviteCodeForm.userInfo')}
							</p>
						</header>
						<div className="card-content">
							<form onSubmit={handleSubmit(onSubmitHandler)}>
								{isEditing ? (
									<div className="field is-horizontal">
										<div className="field-label is-normal">
											<label className="label">{emailMessage}</label>
										</div>
										<div className="field-body">
											<div className="field">
												<div className="control">
													<input
														type="text"
														readOnly="readOnly"
														className="input is-static"
														name="email"
														ref={register}
													/>
												</div>
											</div>
										</div>
									</div>
								) : (
									<>
										<div className="field is-horizontal">
											<div className="field-label is-normal">
												<label className="label">{emailMessage}</label>
											</div>
											<div className="field-body">
												<div className="field">
													<div className="control">
														<input
															className={classNames(`input`, {
																'is-danger': errors.email,
															})}
															ref={register}
															name="email"
														/>
													</div>
												</div>
											</div>
										</div>
										{errors.email && (
											<div className="field is-horizontal">
												<div className="field-label is-normal" />
												<div className="field-body">
													<ErrorMessage text={invalidEmailMessage} />
												</div>
											</div>
										)}
									</>
								)}

								<div className="field is-horizontal">
									<div className="field-label is-normal">
										<label className="label">
											{useFormatMessage('InviteCodeForm.inviteCode')}
										</label>
									</div>
									<div className="field-body">
										<div className="field">
											<div className="control">
												<input
													name="inviteCode"
													id="inviteCode"
													readOnly="readOnly"
													className={classNames('input', {
														'is-danger': errors.inviteCode,
													})}
													ref={register}
													type="text"
												/>
											</div>
										</div>
									</div>
								</div>
								{errors.inviteCode && (
									<div className="field is-horizontal">
										<div className="field-label is-normal" />
										<div className="field-body">
											<ErrorMessage />
										</div>
									</div>
								)}
								{!isProfile && (
									<>
										<div className="field has-check is-horizontal">
											<div className="field-label">
												<label className="label">{donorMessage}</label>
											</div>
											<div className="field-body">
												<div className="field">
													<div className="field">
														<div className="control">
															<label className="b-checkbox checkbox">
																<input
																	type="radio"
																	name="role"
																	value={donorMessage.toLowerCase()}
																	ref={register}
																/>
																<span className="check is-primary" />
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="field has-check is-horizontal">
											<div className="field-label">
												<label className="label">{recipientMessage}</label>
											</div>
											<div className="field-body">
												<div className="field">
													<div className="field">
														<div className="control">
															<label className="b-checkbox checkbox">
																<input
																	type="radio"
																	name="role"
																	value={recipientMessage.toLowerCase()}
																	ref={register}
																/>
																<span className="check is-primary" />
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{errors.role && (
									<div className="field is-horizontal">
										<div className="field-label is-normal" />
										<div className="field-body">
											<ErrorMessage />
										</div>
									</div>
								)}

								<hr />
								<div className="field is-horizontal">
									<div className="field-label" />
									<div className="field-body">
										<div className="field">
											<div className="field is-grouped">
												<div className="control">
													<button
														type="submit"
														className={`button is-primary ${
															loading && 'is-loading'
														}`}>
														<span>{useFormatMessage('UserForm.submit')}</span>
													</button>
												</div>
												{!isProfile && (
													<Link to={paths.USERS} className="button">
														{goBackMessage}
													</Link>
												)}
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

UserForm.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string,
		email: PropTypes.string,
		inviteCode: PropTypes.string,
		role: PropTypes.string,
	}).isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	schema: PropTypes.object.isRequired,
	isEditing: PropTypes.bool,
	isProfile: PropTypes.bool,
};

UserForm.defaultProps = {
	isEditing: false,
	isProfile: false,
};

export default UserForm;
