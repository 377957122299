/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
import React, {useState, useEffect, useCallback} from 'react';
import {useFormatMessage, useDynamicLinks} from 'hooks';
import {Grid, Paper, Box, Stack, Typography} from '@mui/material';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import colors from 'styles/colors';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import {getFirestoreRef, createDocument} from 'state/api/firestore';
import {fetchCollection} from '../../state/api';

const Item = styled(Paper)(({theme}) => ({
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	minHeight: 60,
	padding: '10px',
}));

const Home = () => {
	useDynamicLinks();
	const [isLoading, setIsLoading] = useState(false);
	const [information, setInformation] = useState(null);

	useEffect(() => {
		handleFetchInformation();
	}, []);

	const handleFetchInformation = async () => {
		setIsLoading(true);
		let users = [];
		let blockedUsers = [];
		let connections = [];
		let messagesthreads = [];
		try {
			users = await fetchCollection('users');
			connections = await fetchCollection('connections');
			blockedUsers = await fetchCollection('blocked_users');
			messagesthreads = await fetchCollection('message_threads');
		} catch (e) {
			setIsLoading(false);
			return;
		}

		const totalUsers = users.length;
		let totalThisMonth = 0;
		let totalPassMonth = 0;
		let totalActiveChats = 0;

		for (const i of users) {
			const passMonth = new Date(moment().subtract(1, 'month')).getMonth();
			const currentMonth = new Date().getMonth();
			const time = new Date(i?.createdAt?.seconds * 1000).getMonth();
			if (currentMonth === time) {
				totalThisMonth += 1;
			} else if (passMonth === time) {
				totalPassMonth += 1;
			}
		}

		for (const i of messagesthreads.filter(el => !el?.blocked)) {
			const messages = getFirestoreRef('message_threads')
				.doc(i.id)
				.collection('messages')
				.get();

			if (messages && (await messages).docs.length) {
				totalActiveChats += 1;
			}
		}

		const inactiveUsers = users.filter(el => el?.bannedSystem === true).length;
		const totalActiveConnections = connections.filter(
			el => el?.status.key === 1,
		).length;
		const totalPendingConnections = connections.filter(
			el => el?.status.key === 0,
		).length;
		const totalBlockedUsers = blockedUsers.filter(
			el => el?.active === true,
		).length;
		const currentM = ((totalThisMonth * 100) / totalUsers).toFixed(2);

		setInformation({
			totalUsers,
			totalActive: totalUsers - inactiveUsers,
			totalInactive: inactiveUsers,
			totalActiveConnections,
			totalPendingConnections,
			totalBlockedUsers,
			totalThisMonth,
			totalThisMonthPercent: currentM,
			totalPassMonth,
			totalActiveChats,
		});
		setIsLoading(false);
	};

	const Title = ({title, subtitle}) => {
		return (
			<Stack direction="row" justifyContent="space-between">
				<Typography style={{fontWeight: 'bold', color: 'black'}}>
					{title}
				</Typography>
				<Typography>{subtitle}</Typography>
			</Stack>
		);
	};

	const Number = ({text, extra}) => {
		return isLoading ? (
			<ClipLoader />
		) : (
			<Stack direction="row" alignItems="center">
				<Typography
					variant="h2"
					style={{fontWeight: 'bold', color: colors.primary.blue500}}>
					{text}
				</Typography>
				{extra ? (
					<Typography
						sx={{
							ml: 2,
							backgroundColor: colors.success,
							borderRadius: '5px',
							padding: '1px 5px',
							color: colors.white,
						}}>
						{extra}
					</Typography>
				) : undefined}
			</Stack>
		);
	};

	return (
		<>
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<h1 className="title">{useFormatMessage('Home.home')}</h1>
				</div>
			</section>

			<section className="section is-main-section">
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="New registered users" subtitle="During the month" />
							<Number
								text={information?.totalThisMonth || 0}
								extra={`+ ${information?.totalThisMonthPercent || 0} %`}
							/>
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Registered users" />
							<Number text={information?.totalUsers || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Active users" />
							<Number text={information?.totalActive || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Inactive users" />
							<Number text={information?.totalInactive || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Active connections" />
							<Number text={information?.totalActiveConnections || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Pending connections" />
							<Number text={information?.totalPendingConnections || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Reported users" />
							<Number text={information?.totalBlockedUsers || 0} />
						</Item>
					</Grid>
					<Grid item xs={12} md={3}>
						<Item elevation={1}>
							<Title title="Active chats" />
							<Number text={information?.totalActiveChats || 0} />
						</Item>
					</Grid>
				</Grid>
			</section>
		</>
	);
};

export default Home;
