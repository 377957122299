/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {useFormatMessage, useFormatDate, useDynamicLinks} from 'hooks';
import Table from 'components/Table';
import classes from '../Users/Users.module.scss';
import {fetchCollection} from '../../state/api';

const Connections = ({inviteCode}) => {
	const {id} = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [connections, setConnections] = useState([]);

	useDynamicLinks();

	const [search, setSearch] = useState('');

	useEffect(() => {
		handleFetchConnections();
	}, []);

	const handleFetchConnections = async () => {
		setIsLoading(true);
		let reponse = [];
		try {
			reponse = await fetchCollection('users', {
				queries: [
					{
						attribute: 'invite_code_id',
						operator: '==',
						value: id,
					},
				],
			});
		} catch (e) {
			setIsLoading(false);
			return;
		}
		setConnections(reponse);
		setIsLoading(false);
	};

	const columns = [
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Registered',
			accessor: 'created',
			Cell: ({row}) => (
				<small className="has-text-grey is-abbr-like">
					{useFormatDate(row.original?.createdAt?.seconds * 1000, {
						weekday: 'short',
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})}
				</small>
			),
		},
	];

	const data = search
		? connections.filter(el => {
				const clonedElem = {email: el.email};
				return Object.values(clonedElem).some(field => {
					if (field !== null)
						return field.toLowerCase().includes(search.toLowerCase());
					return false;
				});
		  })
		: connections;

	return (
		<>
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<h1 className="title">
									Users registered with this invite code
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section is-main-section">
				<div className="card has-table has-mobile-sort-spaced">
					<header className="card-header">
						<p className={classNames('card-header-title', classes.tableHeader)}>
							<span>{useFormatMessage('Users.search')}</span>
							<input
								type="text"
								className="input"
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</p>
					</header>
					<div className="b-table">
						{isLoading ? (
							<ClipLoader />
						) : (
							<Table columns={columns} data={data} />
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default Connections;
