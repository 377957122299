import {createReducer} from 'redux-act';

import {
	INVITE_CODES_FETCH_DATA_INIT,
	INVITE_CODES_FETCH_DATA_SUCCESS,
	INVITE_CODES_FETCH_DATA_FAIL,
	INVITE_CODES_DELETE_USER_INIT,
	INVITE_CODES_DELETE_USER_SUCCESS,
	INVITE_CODES_DELETE_USER_FAIL,
	INVITE_CODES_CREATE_USER_INIT,
	INVITE_CODES_CREATE_USER_SUCCESS,
	INVITE_CODES_CREATE_USER_FAIL,
	INVITE_CODES_MODIFY_USER_INIT,
	INVITE_CODES_MODIFY_USER_SUCCESS,
	INVITE_CODES_MODIFY_USER_FAIL,
	INVITE_CODES_CLEAN_UP,
	INVITE_CODES_CLEAR_DATA_LOGOUT,
} from 'state/actions/invite_codes';

const initialState = {
	data: [],
	loading: false,
	error: null,
	success: false,
	deleted: false,
};

export const inviteCodesReducer = createReducer(
	{
		[INVITE_CODES_FETCH_DATA_INIT]: () => ({
			...initialState,
			loading: true,
		}),
		[INVITE_CODES_FETCH_DATA_SUCCESS]: (state, payload) => {
			return {
				...state,
				data: payload.data,
				loading: false,
				error: null,
			};
		},
		[INVITE_CODES_FETCH_DATA_FAIL]: (state, payload) => ({
			...state,
			loading: false,
			error: payload.error,
		}),
		[INVITE_CODES_DELETE_USER_INIT]: state => ({
			...state,
			loading: true,
		}),
		[INVITE_CODES_DELETE_USER_SUCCESS]: (state, payload) => ({
			...state,
			data: state.data.filter(elem => elem.id !== payload.id),
			loading: false,
			error: null,
			deleted: true,
		}),
		[INVITE_CODES_DELETE_USER_FAIL]: (state, payload) => ({
			...state,
			loading: false,
			error: payload.error,
		}),
		[INVITE_CODES_CREATE_USER_INIT]: state => ({
			...state,
			loading: true,
		}),
		[INVITE_CODES_CREATE_USER_SUCCESS]: (state, payload) => ({
			...state,
			data: state.data.concat(payload.user),
			loading: false,
			error: null,
			success: true,
		}),
		[INVITE_CODES_CREATE_USER_FAIL]: (state, payload) => ({
			...state,
			loading: false,
			error: payload.error,
		}),
		[INVITE_CODES_MODIFY_USER_INIT]: state => ({
			...state,
			loading: true,
		}),
		[INVITE_CODES_MODIFY_USER_SUCCESS]: (state, payload) => ({
			...state,
			data: !state.data
				? []
				: state.data.map(elem => {
						if (elem.id === payload.id) {
							return {
								name: payload.user.name,
								location: payload.user.location,
								id: payload.id,
								logoUrl: payload.user.logoUrl,
								createdAt: payload.user.createdAt,
								email: elem.email,
							};
						}
						return elem;
				  }),
			loading: false,
			error: null,
			success: true,
		}),
		[INVITE_CODES_MODIFY_USER_FAIL]: (state, payload) => ({
			...state,
			loading: false,
			error: payload.error,
		}),
		[INVITE_CODES_CLEAN_UP]: state => ({
			...state,
			loading: false,
			error: null,
			success: false,
			deleted: false,
		}),
		[INVITE_CODES_CLEAR_DATA_LOGOUT]: () => ({
			...initialState,
		}),
	},
	initialState,
);
