/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {useDynamicLinks} from 'hooks';
import ClipLoader from 'react-spinners/ClipLoader';
import {
	Box,
	TextField,
	Button,
	Stack,
	Typography,
	Switch,
	Grid,
} from '@mui/material';
import {capitalizeFirstLetter} from 'utils';
import ConfirmationModal from 'components/ConfirmationModal';
import {deleteInivteCode} from 'state/actions/invite_codes';
import Users from './Users';
import {fetchDocument} from '../../state/api';

const InviteCodeDetails = () => {
	useDynamicLinks();
	const {id} = useParams();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [inviteCode, setInviteCode] = useState(null);
	const [disabledModal, setDisabledModal] = useState(false);

	useEffect(() => {
		if (id) {
			handleFetchInviteCodeInfo();
		}
	}, [id]);

	const handleFetchInviteCodeInfo = async () => {
		setIsLoading(true);
		let reponse = null;
		try {
			reponse = await fetchDocument('invite_codes', id);
			setInviteCode(reponse);
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};

	const onHandleDisabledModal = () => {
		setDisabledModal(prevState => !prevState);
	};

	const onDeleteUserHandler = () => {
		setIsLoading(true);
		dispatch(deleteInivteCode(id));
		onHandleDisabledModal();
		handleFetchInviteCodeInfo();
	};

	return (
		<>
			{disabledModal && (
				<ConfirmationModal
					isActive={disabledModal}
					isLoading={isLoading}
					confirmButtonMessage="Yes, confirm"
					title="Action"
					body={
						inviteCode?.deleted
							? 'This will activate this invitation code to be used again.'
							: 'This will deactivate this invitation code to be used again.'
					}
					cancelButtonMessage="Cancel"
					onConfirmation={onDeleteUserHandler}
					onCancel={onHandleDisabledModal}
				/>
			)}
			<section className="hero is-hero-bar">
				<div className="hero-body">
					<h1 className="title">
						Invite Code Details ({inviteCode ? inviteCode.invite_code : ''})
					</h1>
				</div>
			</section>
			<section className="section is-main-section">
				{isLoading ? (
					<ClipLoader />
				) : (
					<>
						<div className="tile is-ancestor">
							<div className="tile is-parent">
								<div className="card tile is-child">
									<header className="card-header">
										<p className="card-header-title">Actions</p>
									</header>
									<div className="card-content">
										<Grid container>
											<Grid
												item
												xs={12}
												style={{display: 'flex', alignItems: 'center'}}>
												{isLoading ? (
													<ClipLoader />
												) : (
													<Switch
														disabled={isLoading}
														checked={inviteCode?.deleted}
														onChange={onHandleDisabledModal}
													/>
												)}
												<Typography>
													Disabled invite code to be used again
												</Typography>
											</Grid>
										</Grid>
									</div>
								</div>
							</div>
						</div>

						<Users inviteCode={inviteCode} />
					</>
				)}
			</section>
		</>
	);
};

export default InviteCodeDetails;
