export default {
	ROOT: '/',
	LOGIN: '/login',

	USERS: '/users',
	ADD_USER: '/users/new',
	MODIFY_USER: '/users/:id',

	USER_DETAILS: '/users-details/:id',
	USER_DETAILS_MESSAGES: '/connection-messages/:threadId',

	INVITE_CODES: '/invite-codes',
	ADD_INVITE_CODE: '/invite-codes/new',
	MODIFY_INVITE_CODE: '/invite-codes/:id',
	INVITE_CODE_DETAILS: '/invite-code-details/:id',

	PROFILE: '/profile',
	RESET_PASSWORD: '/recover-password',
	SECTION: '/section',
	SUBMENU_1: '/submenu1',
	SUBMENU_2: '/submenu2',
};
