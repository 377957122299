import {createTheme} from '@mui/material/styles';
import colors from './colors';

const theme = createTheme({
	palette: {
		primary: {
			// Purple and green play nicely together.
			main: colors.primary.blue500,
		},
		secondary: {
			// This is green.A700 as hex.
			main: colors.secondary.pink500,
		},
	},
});

export default theme;
