/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import {Chip, Stack, Avatar, Typography} from '@mui/material';
import {useFormatMessage, useFormatDate, useDynamicLinks} from 'hooks';
import Table from 'components/Table';
import {
	fetchUsers,
	deleteUser,
	enableUserAccount,
	usersCleanUp,
} from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import {Visibility as VisibilityIcon} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {capitalizeFirstLetter} from 'utils';
import classes from './Users.module.scss';

const Users = () => {
	useDynamicLinks();

	const {usersList, isAdmin, error, loading, deleted} = useSelector(
		state => ({
			usersList: state.users.data,
			isAdmin: state.auth.userData.isAdmin || state.auth.userData.is_admin,
			error: state.users.error,
			loading: state.users.loading,
			deleted: state.users.deleted,
		}),
		shallowEqual,
	);

	const [bannedModal, setBannedModal] = useState({
		userId: null,
		isOpen: false,
	});

	const [activeModal, setActiveModal] = useState({
		userId: null,
		isOpen: false,
	});

	const dispatch = useDispatch();

	const [search, setSearch] = useState('');

	useEffect(() => {
		dispatch(fetchUsers());
		return () => dispatch(usersCleanUp());
	}, [dispatch, isAdmin]);

	useEffect(() => {
		if (deleted && !loading) {
			onHandleBannedCloseModal();
			onHandleActiveCloseModal();
			dispatch(fetchUsers());
		}
	}, [deleted, loading]);

	const onHandleBannedCloseModal = userId => {
		setBannedModal(prevState => ({userId: null, isOpen: false}));
	};

	const onHandleActiveCloseModal = userId => {
		setActiveModal(prevState => ({userId: null, isOpen: false}));
	};

	const onDeleteUserHandler = () => {
		dispatch(deleteUser(bannedModal.userId));
	};

	const onActiveUserHandler = () => {
		dispatch(enableUserAccount(activeModal.userId));
	};

	const columns = [
		{
			Header: 'User',
			accessor: 'userdetails',
			Cell: ({row}) => (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Avatar>
						{row.original.name ? row.original.name.slice(0, 2) : null}
					</Avatar>
					<Stack>
						<Typography fontWeight="bold">{row.original.name}</Typography>
						<Typography>{row.original.email}</Typography>
					</Stack>
				</Stack>
			),
		},
		{
			Header: 'Permissions',
			accessor: 'isAdmin',
			Cell: ({row}) => (
				<small className="has-text-grey is-abbr-like">
					{row.original.isAdmin || row.original.is_admin ? (
						<Chip label="Admin" color="success" />
					) : (
						<Chip label="User" />
					)}
				</small>
			),
		},
		{
			Header: 'Role',
			accessor: 'role',
			Cell: ({row}) => (
				<small className="has-text-grey is-abbr-like">
					<Chip label={capitalizeFirstLetter(row.original.role)} />
				</small>
			),
		},
		{
			Header: () => <div style={{textAlign: 'center'}}>Chat Status</div>,
			accessor: 'chat-status',
			Cell: ({row}) => (
				<Stack direction="row" justifyContent="center">
					<Chip
						label={`${
							row.original?.bannedCommunications
								? 'Blocked to communicate with anyone'
								: row.original?.reportsCounts > 0
								? `Blocked to communicate with ${row.original?.reportsCounts} users`
								: 'Active to communicate with anyone'
						}`}
						color={
							row.original?.reportsCounts >= 1 ||
							row.original?.bannedCommunications
								? 'error'
								: 'default'
						}
						variant="outlined"
					/>
				</Stack>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({row}) => (
				<Stack direction="row" spacing={1}>
					<Chip
						label={row.original?.bannedSystem ? 'Disable' : 'Active'}
						color={row.original?.bannedSystem ? 'error' : 'success'}
					/>
				</Stack>
			),
		},
		{
			Header: useFormatMessage('Users.created'),
			accessor: 'created',
			Cell: ({row}) => (
				<small className="has-text-grey is-abbr-like">
					{row.original?.createdAt
						? useFormatDate(row.original?.createdAt?.seconds * 1000)
						: ''}
				</small>
			),
		},
		{
			Header: '',
			id: 'actions',
			accessor: 'actions',
			Cell: ({row}) => (
				<>
					{(!row.original.isAdmin || !row.original.is_admin) && (
						<div className="buttons is-right">
							{/* {
								row.original?.bannedSystem ? 
								<Tooltip title="Active">
									<IconButton color="success" component="span" onClick={() => onHandleActiveModal(row.original.id)}>
										<RestoreIcon />
									</IconButton>
								</Tooltip>
								: <Tooltip title="Disable">
									<IconButton color="warning" component="span" onClick={() => onHandleBannedModal(row.original.id)}>
										<BlockIcon />
									</IconButton>
								</Tooltip>
							} */}

							<Link to={`/users-details/${row.original.id}`}>
								<IconButton color="primary" component="span">
									<VisibilityIcon />
								</IconButton>
							</Link>
						</div>
					)}
				</>
			),
			disableSortBy: true,
		},
	];

	const data = search
		? usersList.filter(el => {
				const clonedElem = {name: el.name, email: el.email};
				return Object.values(clonedElem).some(field => {
					if (field !== null)
						return field.toLowerCase().includes(search.toLowerCase());
					return false;
				});
		  })
		: usersList;

	const cancelMessage = useFormatMessage('Users.cancel');

	return (
		<>
			{activeModal.isOpen && (
				<ConfirmationModal
					isActive={activeModal.isOpen}
					isLoading={loading}
					confirmButtonMessage="Yes, Active"
					title="Activate user account"
					body="This will unblock the user account, and the user will be able to log in to the platform again"
					cancelButtonMessage={cancelMessage}
					onConfirmation={onActiveUserHandler}
					onCancel={onHandleActiveCloseModal}
				/>
			)}

			{bannedModal.isOpen && (
				<ConfirmationModal
					isActive={bannedModal.isOpen}
					isLoading={loading}
					confirmButtonMessage="Yes, Deactive"
					title="Deactivate user account"
					body="This will block the user account, and the user will not be able to log in to the platform again."
					cancelButtonMessage={cancelMessage}
					onConfirmation={onDeleteUserHandler}
					onCancel={onHandleBannedCloseModal}
				/>
			)}

			<section className="hero is-hero-bar">
				<div className="hero-body">
					<div className="level">
						<div className="level-left">
							<div className="level-item">
								<h1 className="title">{useFormatMessage('Users.users')}</h1>
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">
								<Link to={paths.ADD_USER} className="button">
									{useFormatMessage('Users.newUser')}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section is-main-section">
				<div className="card has-table has-mobile-sort-spaced">
					<header className="card-header">
						<p className={classNames('card-header-title', classes.tableHeader)}>
							<span>{useFormatMessage('Users.search')}</span>
							<input
								type="text"
								className="input"
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</p>
					</header>
					<div className="b-table">
						{loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
						{error && 'Show error'}
					</div>
				</div>
			</section>
		</>
	);
};

export default Users;
